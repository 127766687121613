import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

import jquery from "jquery"
window.$ = jquery

$(document).ready(function () {
  $('.client-slider').slick({
    slidesToShow: 3,
    infinite: true,
    dots: true
  });
});

export { application }

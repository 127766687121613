import { Controller } from "@hotwired/stimulus"
import Chart_js from "chart.js"

// Connects to data-controller="dashboard-graph"
export default class extends Controller {
  static values = {
    label: Array,
    cvs: Array,
    cvcps: Array
  }

  connect() {
    console.log( 'loaded chart.js' )

    this.draw()
  }

  draw() {
    var areaChartData = {
      labels  : this.labelValue,
      datasets: [
        {
          label               : 'CV数',
          backgroundColor     : '#198754',
          borderColor         : '#198754',
          pointRadius          : false,
          pointColor          : '#3b8bba',
          pointStrokeColor    : '#198754',
          pointHighlightFill  : '#fff',
          pointHighlightStroke: '#198754',
          data                : this.cvsValue
        },
        {
          label               : '配信ユーザー数',
          backgroundColor     : '#0dcaf0',
          borderColor         : '#0dcaf0',
          pointRadius         : false,
          pointColor          : '#0dcaf0',
          pointStrokeColor    : '#c1c7d1',
          pointHighlightFill  : '#fff',
          pointHighlightStroke: '#0dcaf0',
          data                : this.cvcpsValue
        },
      ]
    }

    var barChartCanvas = $('#barChart').get(0).getContext('2d')
    var barChartData = $.extend(true, {}, areaChartData)
    barChartData.datasets[0] = areaChartData.datasets[0]
    barChartData.datasets[1] = areaChartData.datasets[1]

    var barChartOptions = {
      scales: {
        yAxes: [{
          ticks: {
            suggestedMin: 0,
            stepSize: 10
          }
        }]
      },
      responsive              : true,
      maintainAspectRatio     : false,
      datasetFill             : false
    }

    var marketingOverview = new Chart(barChartCanvas, {
      type: 'bar',
      data: barChartData,
      options: barChartOptions
    })
  }
}

import { Controller } from "@hotwired/stimulus"
import Chart_js from "chart.js"

// Connects to data-controller="cancel-graph"
export default class extends Controller {
  static values = {
    requestInterviewLabel: Array,
    requestInterview: Array,
    meetingLabel: Array,
    meeting: Array
  }
  
  connect() {
    this.draw()
  }

  draw() {
    var areaChartRequestInterviewData = {
      labels  : this.requestInterviewLabelValue,
      datasets: [
        {
          label               : '',
          backgroundColor     : '#198754',
          borderColor         : '#198754',
          pointRadius         : false,
          pointColor          : '#3b8bba',
          pointStrokeColor    : '#198754',
          pointHighlightFill  : '#fff',
          pointHighlightStroke: '#198754',
          data                : this.requestInterviewValue
        }
      ]
    }

    var barChartRequestInterviewCanvas = $('#barChartCancelRequestInterview').get(0).getContext('2d')
    var barChartRequestInterviewData = $.extend(true, {}, areaChartRequestInterviewData)
    barChartRequestInterviewData.datasets[0] = areaChartRequestInterviewData.datasets[0]

    var barChartOptions = {
      scales: {
        yAxes: [{
          ticks: {
            suggestedMin: 0,
            stepSize: 10
          }
        }]
      },
      responsive              : true,
      maintainAspectRatio     : false,
      datasetFill             : false,
      legend: {
        display: false
      },
      tooltips: {
        callbacks: {
          title: function(tooltipItem, data) {
            return data['labels'][tooltipItem[0]['index']] + ": " + tooltipItem[0]['yLabel'] + "件";
          },
          label: function(tooltipItem, data) {
            return "";
          },
        },
      }
    }

    new Chart(barChartRequestInterviewCanvas, {
      type: 'bar',
      data: barChartRequestInterviewData,
      options: barChartOptions
    })

    var areaChartMeetingData = {
      labels  : this.meetingLabelValue,
      datasets: [
        {
          label               : '',
          backgroundColor     : '#198754',
          borderColor         : '#198754',
          pointRadius         : false,
          pointColor          : '#3b8bba',
          pointStrokeColor    : '#198754',
          pointHighlightFill  : '#fff',
          pointHighlightStroke: '#198754',
          data                : this.meetingValue
        }
      ]
    }

    var barChartMeetingCanvas = $('#barChartCancelMeeting').get(0).getContext('2d')
    var barChartMeetingData = $.extend(true, {}, areaChartMeetingData)
    barChartMeetingData.datasets[0] = areaChartMeetingData.datasets[0]

    new Chart(barChartMeetingCanvas, {
      type: 'bar',
      data: barChartMeetingData,
      options: barChartOptions
    })
  }
}

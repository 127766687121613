import { Controller } from "@hotwired/stimulus"
import Select2 from "select2"
import jpostal from "jquery-jpostal-ja"

// Connects to data-controller="simulation-form"
export default class extends Controller {
  static targets = [ 
    'age', 
    'sex_id', 
    'zipcode', 
    'prefecture_id', 
    'address', 
    'job_id', 
    'work_place_attribute_id', 
    'annual_income', 
    'personal_annual_income', 
    'family_structure', 
    'saving_price', 
    'max_investment_price', 
    'investment_experience', 
    'investment_ids', 
    'has_current_insurance_false', 
    'has_current_insurance_true', 
    'current_insurance', 
    'desired_interview', 
    'is_meet_online', 
    'is_meet_offline', 
    'user_name_sei', 
    'user_name_mei', 
    'user_kana_sei', 
    'user_kana_mei', 
    'tel', 
    'email', 
    'submit_btn' 
  ]

  check = {
    age: false,
    sex_id: false,
    zipcode: false,
    prefecture_id: false,
    address: false,
    job_id: false,
    work_place_attribute_id: false,
    annual_income: false,
    personal_annual_income: false,
    family_structure: false,
    saving_price: false,
    max_investment_price: false,
    investment_experience: false,
    investment_ids: false,
    has_current_insurance_false: true,
    has_current_insurance_true: true,
    current_insurance: true,
    desired_interview: false,
    is_meet_available: false,
    user_name_sei: false,
    user_name_mei: false,
    user_kana_sei: false,
    user_kana_mei: false,
    tel: false,
    email: true
  }

  connect() {
    Select2();

    $('[name="conversion[zipcode]"]').jpostal({
      postcode : [
        '[name="conversion[zipcode]"]',
      ],
      address : {
        '[name="conversion[prefecture_id]"]' : '%3',
        '[name="conversion[address]"]' : '%4%5',
      }
    });

    const investmentIdsSelect = $('#conversion_investment_ids');

    investmentIdsSelect.select2({
      width: '100%',
      lang: 'ja',
    });

    investmentIdsSelect.on('change.select2', () => {
      this.validate();
    });

    $("input[data-type='currency']").on({
      keyup: function() {
        formatCurrency($(this));
      },
      blur: function() {
        formatCurrency($(this), "blur");
      }
    });

    function formatCurrencyNumber(number) {
      // format number 1000000 to 1,234,567
      return number.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    function formatCurrency(input, blur) {
      // get input value
      var input_val = input.val();

      // don't validate empty input
      if (input_val === "") { return; }

      // format currency
      input_val = formatCurrencyNumber(input_val);
      input_val = input_val;

      // send updated string to input
      input.val(input_val);
    }

    $('[name="conversion[has_current_insurance]"]').on("change", function() {
      let has_current_insurance = $(this).val();

      $(".current_insurance_input").css('display', 'none');
      if (has_current_insurance == "true") {
        $(".current_insurance_input").css('display', 'block');
      }
      $("#conversion_current_insurance").val("");
    });

    $('#btn_submit').on("click", () => {
      this.validFields();
      var invalidField = this.findFirstInvalidField();

      if (invalidField) {
        if (invalidField == 'is_meet_available') {
          invalidField = 'is_meet_online';
        }
        this.scrollAndFocus(invalidField);
      }
    });
  }

  validate(){

    // 年齢
    if ( this.ageTarget.value ) {
      this.check.age = true
    } else {
      this.check.age = false
    }
    
    // 性別
    if ( this.sex_idTarget.value ) {
      this.check.sex_id = true
    } else {
      this.check.sex_id = false
    }

    // 職業
    if ( this.job_idTarget.value ) {
      this.check.job_id = true
    } else {
      this.check.job_id = false
    }
    
    if ( this.zipcodeTarget.value ) {
      this.check.zipcode = true
    } else {
      this.check.zipcode = false
    }

    if ( this.prefecture_idTarget.value ) {
      this.check.prefecture_id = true
    } else {
      this.check.prefecture_id = false
    }
    
    if ( this.addressTarget.value ) {
      this.check.address = true
    } else {
      this.check.address = false
    }

    if ( this.work_place_attribute_idTarget.value ) {
      this.check.work_place_attribute_id = true
    } else {
      this.check.work_place_attribute_id = false
    }

    this.check.annual_income = this.isNumberMoneyValid(this.annual_incomeTarget.value);

    this.check.personal_annual_income = this.isNumberMoneyValid(this.personal_annual_incomeTarget.value);

    if ( this.family_structureTarget.value ) {
      this.check.family_structure = true
    } else {
      this.check.family_structure = false
    }

    this.check.saving_price = this.isNumberMoneyValid(this.saving_priceTarget.value);

    this.check.max_investment_price = this.isNumberMoneyValid(this.max_investment_priceTarget.value);

    if ( this.investment_experienceTarget.value ) {
      this.check.investment_experience = true
    } else {
      this.check.investment_experience = false
    }

    var selectedInvestments = $( '#conversion_investment_ids' ).select2('data');
    if (selectedInvestments.length > 0) {
      this.check.investment_ids = true;
    } else {
      this.check.investment_ids = false;
    }
    
    if ( this.has_current_insurance_falseTarget.checked || (this.has_current_insurance_trueTarget.checked && this.current_insuranceTarget.value) ) {
      this.check.current_insurance = true
    } else {
      this.check.current_insurance = false
    }

    if ( this.desired_interviewTarget.value ) {
      this.check.desired_interview = true
    } else {
      this.check.desired_interview = false
    }
    
    if ( this.is_meet_onlineTarget.checked || this.is_meet_offlineTarget.checked ) {
      this.check.is_meet_available = true
    } else {
      this.check.is_meet_available = false
    }

    // ユーザー姓
    if ( this.user_name_seiTarget.value ) {
      this.check.user_name_sei = true
    } else {
      this.check.user_name_sei = false
    }

    // ユーザー名
    if ( this.user_name_meiTarget.value ) {
      this.check.user_name_mei = true
    } else {
      this.check.user_name_mei = false
    }

    // ユーザーセイ
    if ( this.user_kana_seiTarget.value ) {
      this.check.user_kana_sei = true
    } else {
      this.check.user_kana_sei = false
    }

    // ユーザーカナ
    if ( this.user_kana_meiTarget.value ) {
      this.check.user_kana_mei = true
    } else {
      this.check.user_kana_mei = false
    }

    // 電話番号
    var tel = this.telTarget.value.replace( /-/g, '' )
    if ( ! this.telTarget.value ) {
      this.check.tel = false;
      $("#tel_error").text("電話番号を入力してください。");
    } else if (!tel.match(/(.)\1{4,}/) && tel.match(/^0[789]0/) && /^0[789]0[0-9]{8}$/.test(tel) && !/^08012345678$/.test(tel)) {
      this.check.tel = true;
    } else {
      this.check.tel = false;
      $("#tel_error").text("電話番号のフォーマットが正しくありません。");
    }

    if (!this.emailTarget.value) {
      this.check.email = true;
    } else if (!/^.+@.+\..+$/.test(this.emailTarget.value)) {
      this.check.email = false;
    } else {
      this.check.email = true;
    }

    Object.entries(this.check).forEach(([key, value]) => {
      if (value === true) {
        $(`#${key}_error`).addClass("hide");
      }
    });

    var checkResult = Object.keys( this.check ).every((key) => {
      return this.check[key];
    });

    if ( checkResult ) {
      this.submit_btnTarget.type = 'submit';
    } else {
      this.submit_btnTarget.type = 'button';
    }
  }
  
  findFirstInvalidField() {
    var invalidField;
    for (var key in this.check) {
      if (this.check.hasOwnProperty(key) && !this.check[key]) {
        invalidField = key;
        break;
      }
    }
    return invalidField;
  }

  scrollAndFocus(field) {
    var targetField = this[field + 'Target'];
    if (targetField) {
      $('html, body').animate({
        scrollTop: $(targetField).offset().top - 20
      }, 500);
      
      $(targetField).focus();
    }
  }

  validFields() {
    for (var key in this.check) {
      if (this.check.hasOwnProperty(key) && !this.check[key]) {
        $(`#${key}_error`).removeClass("hide");
      } else {
        $(`#${key}_error`).addClass("hide");
      }
    }
  }

  isNumberMoneyValid(val) {
    if (!val) return false;

    let formatValue = val.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (formatValue == '') return false;

    return true;
  }
}

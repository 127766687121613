var Trix = require("trix");

Trix.config.blockAttributes.speeka = {
  tagName: "speeka",
  terminal: true,
  breakOnReturn: true,
  group: false
}

Trix.config.blockAttributes.speekq = {
  tagName: "speekq",
  terminal: true,
  breakOnReturn: true,
  group: false
}

window.addEventListener("trix-initialize", function(event) {
  var buttonHTML = '<button type="button" data-trix-attribute="speeka">答え</button>'

  event.target.toolbarElement.
  querySelector(".trix-button-group").
  insertAdjacentHTML("beforeend", buttonHTML)
})

window.addEventListener("trix-initialize", function(event) {
  var buttonHTML = '<button type="button" data-trix-attribute="speekq">質問</button>'

  event.target.toolbarElement.
  querySelector(".trix-button-group").
  insertAdjacentHTML("beforeend", buttonHTML)
})

$('.trix-content').on('trix-change', function() {
  var rawContent = $('#article_content').val();
  var seoContent = rawContent.replace(/<[^>]*>/g, '');

  $("#article_seo_content").val(seoContent.substring(0,200)); // Only keep 200 characters
})

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="simple-form"
export default class extends Controller {
  static targets = [ 'age', 'age_wrap', 'sex_id', 'sex_id_wrap', 'job_id', 'job_id_wrap', 'saving_id', 'saving_id_wrap', 'max_investment_id', 'max_investment_id_wrap', 'tel', 'tel_wrap', 'tel_att_wrap', 'email', 'email_wrap', 'submit_btn' ]

  connect() {
    console.log( 'load simple_form' )
  }

  validate(){
    var check = {
      age: false,
      sex_id: false,
      job_id: false,
      saving_id: false,
      max_investment_id: false,
      tel: false,
      email: true
    }

    // 年齢
    if ( this.ageTarget.value ) {
      check.age = true
    } else {
      check.age = false
    }

    // 性別
    if ( this.sex_idTarget.value ) {
      check.sex_id = true
    } else {
      check.sex_id = false
    }

    // 職業
    if ( this.job_idTarget.value ) {
      check.job_id = true
    } else {
      check.job_id = false
    }

    // 貯金額
    if ( this.saving_idTarget.value ) {
      check.saving_id = true
    } else {
      check.saving_id = false
    }

    // 月々の投資額
    if ( this.max_investment_idTarget.value ) {
      check.max_investment_id = true
    } else {
      check.max_investment_id = false
    }

    // 電話番号
    var tel = this.telTarget.value.replace( /-/g, '' )
    if ( ! tel.match( /(.)\1{4,}/ ) ) {
      if ( tel.match( /^0[789]0/ ) ) {
        if ( /^0[789]0[0-9]{8}$/.test( tel ) && ! /^08012345678$/.test( tel ) ) {
          check.tel = true
        } else {
          check.tel = false
        }
      } else {
        if ( /^0[1-9]\d{8}$/.test( tel ) ) {
          check.tel = false
        }
      }
    } else {
      check.tel = false
    }

    // メールアドレス
    var emailSplit = this.emailTarget.value.split( '@' )
    if ( ! this.emailTarget.value ) {
      check.email = true
    } else if ( ! /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test( this.emailTarget.value ) && emailSplit[0].length > 1 ) {
      check.email = false
    } else {
      check.email = true
    }

    var falseKey = '';
    var valids = [];

    var checkResult = Object.keys( check ).every( function( key ) {
      if ( ! check[key] ) {
        falseKey = key
      }
      return check[key]
    })

    var row_items = document.getElementsByClassName( 'form-input__row' )
    Array.from(row_items).forEach(function( element ){
      element.classList.remove( 'is-active' )
    })

    if ( checkResult ) {
      this.submit_btnTarget.classList.remove( 'is-disable' )
      this.submit_btnTarget.disabled = false
    } else {
      eval( 'this.' + falseKey + '_wrapTarget.classList.add( "is-active" )')
      if ( falseKey == 'email' || falseKey == 'tel' ) {
        eval( 'this.' + falseKey + '_wrapTarget.classList.remove( "is-hidden" )')
        this.email_wrapTarget.classList.remove( "is-hidden" )
        this.tel_att_wrapTarget.classList.remove( "is-hidden" )
      }
      this.submit_btnTarget.classList.add( 'is-disable' )
      this.submit_btnTarget.disabled = true
    }
  }
}

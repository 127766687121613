import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form"
export default class extends Controller {
  static targets = [ 'age', 'saving_id', 'max_investment_id', 'personal_field', 'tel', 'email', 'submit_btn' ]

  connect() {
    console.log( 'form_controller.js connected' )
  }

  validate(){
    var check = {
      age: false,
      saving_id: false,
      max_investment_id: false,
      tel: false,
      email: true
    }

    // 年齢
    if ( this.ageTarget.value ) {
      check.age = true
    } else {
      check.age = false
    }

    // 貯金額
    if ( this.saving_idTarget.value ) {
      check.saving_id = true
    } else {
      check.saving_id = false
    }

    // 月々の投資額
    if ( this.max_investment_idTarget.value ) {
      check.max_investment_id = true
    } else {
      check.max_investment_id = false
    }

    // 電話番号
    var tel = this.telTarget.value.replace( /-/g, '' )
    if ( ! tel.match( /(.)\1{4,}/ ) ) {
      if ( tel.match( /^0[789]0/ ) ) {
        if ( /^0[789]0[0-9]{8}$/.test( tel ) && ! /^08012345678$/.test( tel ) ) {
          check.tel = true
        } else {
          check.tel = false
        }
      } else {
        if ( /^0[1-9]\d{8}$/.test( tel ) ) {
          check.tel = false
        }
      }
    } else {
      check.tel = false
    }

    // メールアドレス
    var emailSplit = this.emailTarget.value.split( '@' )
    if ( ! this.emailTarget.value ) {
      check.email = true
    } else if ( ! /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test( this.emailTarget.value ) && emailSplit[0].length > 1 ) {
      check.email = false
    } else {
      check.email = true
    }

    // 3項目入力済みの場合
    if ( check.age && check.saving_id && check.max_investment_id ) {
      if ( ! this.personal_fieldTarget.classList.contains( 'is--opened' ) ) {
        this.personal_fieldTarget.classList.add( 'is--opened' )
        this.personal_fieldTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }

    var checkResult = Object.keys( check ).every( function( key ) {
      return check[key]
    })

    if ( checkResult ) {
      this.submit_btnTarget.classList.remove( 'is-disable' )
      this.submit_btnTarget.disabled = false
    } else {
      this.submit_btnTarget.classList.add( 'is-disable' )
      this.submit_btnTarget.disabled = true
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import Select2 from "select2"
import moment from "moment"
import "./trix_controller"
import jpostal from "jquery-jpostal-ja"

// Connects to data-controller="admin"
export default class extends Controller {
  static targets = [ 'transport' ]
  static values = {
    id: Number
  }

  connect() {
    Select2();

    let formInvalid = []
    const DATE_FORMAT = 'YYYY/MM/DD'

    $( '.select-2' ).select2({
      width: '100%',
      lang: 'ja',
    });

    $('.btn-select-all').off('click').click(function(){
      const collection_id = $(this).parents().children('.select-2').attr('id');

      $(`.select-2#${collection_id} > option`).prop('selected', 'selected');
      $('.select-2').trigger('change');

      return false;
    });

    $('[name="conversion[zipcode]"]').jpostal({
      postcode : [
        '[name="conversion[zipcode]"]',
      ],
      address : {
        '[name="conversion[prefecture_id]"]' : '%3',
        '[name="conversion[address]"]' : '%4%5',
      }
    });

    initDatepicker()

    // add category
    $('#category_name').on('keyup', function() {
      if ($(this).val()) {
        $('.confirm-request-add-category').removeClass('disabled');
      } else {
        $('.confirm-request-add-category').addClass('disabled');
      }
    })

    // multiple emails on edit company screen
    $('.btn-add-email').off('click').click(function() {
      let emailItemHtml = $('#emails-wrap .email-item.d-none')[0].outerHTML

      emailItemHtml = emailItemHtml.replaceAll('d-none', '')
      $('#emails-wrap').append(emailItemHtml)

      checkEmailPresent()
    })

    $('#company-form').off('click', '#recommend-cvs-for-company').on('click', '#recommend-cvs-for-company', function(e) {
      const companyId = $(this).val()

      $.ajax({
        url: `/admins/companies/${companyId}/recommend_old_cvs`,
        type: 'POST',
        dataType: 'json',
        data: {},
        beforeSend: function() {
          $('#loading').removeClass('hide')
          $('#loading').addClass('show')
        }
      }).done(function (data) {
        $('#loading').addClass('hide')
        $('#loading').removeClass('show')

        const $alert = $('.recommend-cvs-alert')

        if(data.status ==  "OK") {
          $alert.removeClass('d-none')

          setTimeout(() => {
            $alert.addClass('d-none')
          }, 5000);
        } else {
          $alert.removeClass('d-none', 'alert-success')
          $alert.addClass('alert-danger')
          $alert.find('span').html(data.msg)
        }
      })

      $('#loading').addClass('hide')
    })

    $('.recommend-cvs-alert').on('click', '.close', function () {
      $('.recommend-cvs-alert').addClass('d-none')
    })

    $('#emails-wrap').on('click', '.btn-remove-email', function() {
      $(this).parents('.email-item').remove()

      checkEmailPresent()
    })

    $('#emails-wrap').on('blur', '.email-input', function() {
      checkEmailInvalid($(this).val(), $(this))
    })

    $('#company_investment_ids').on('select2:select', function (e) {
      const beforeInvestmentIds = $('#before_investment_ids').val().split(" ")

      const afterInvestmentIds = $(this).val()
      const newArrInvestmentIds = afterInvestmentIds.filter(x => !beforeInvestmentIds.includes(x))

      if (newArrInvestmentIds.length) {
        const selectedData = $(this).select2('data')
        const newArrInvestmentTexts = selectedData.filter(obj => newArrInvestmentIds.includes(obj.id)).map(obj => obj.text)
        const newInvestments = "「" + newArrInvestmentTexts.join(", ") + "」"

        $('#modal-confirm-change-investment').find('.new-investments').text(newInvestments)

        $('.submit-company-form').each(function(){
          $(this).attr('data-bs-toggle', 'modal')
          $(this).attr('data-bs-target', '#modal-confirm-change-investment')
        })
      }
    })

    $('#company_investment_ids').on('select2:unselect', function(e) {
      const beforeInvestmentIds = $('#before_investment_ids').val().split(" ")

      const afterInvestmentIds = $(this).val()
      const newArrInvestmentIds = afterInvestmentIds.filter(x => !beforeInvestmentIds.includes(x))

      const selectedData = $(this).select2('data')
      const newArrInvestmentTexts = selectedData.filter(obj => newArrInvestmentIds.includes(obj.id)).map(obj => obj.text)
      const newInvestments = "「" + newArrInvestmentTexts.join(", ") + "」"

      $('#modal-confirm-change-investment').find('.new-investments').text(newInvestments)

      if (newArrInvestmentIds.length === 0) {
        $('.submit-company-form').each(function(){
          $(this).attr('data-bs-toggle', '')
          $(this).attr('data-bs-target', '')
        })
      }
    })

    $('.submit-company-form').off('click').on('click', function(e) {
      if($(this).attr('data-bs-target')) {
        return false
      } else {
        $('#company-form').submit()
      }
    })

    $('#company-form').off('submit').on('submit', function() {
      checkEmailWrap()
      checkInvoiceEmailWrap()

      if (!formInvalid.every(v => !v)) {
        const $errorMessages = $(this).find('.error-message').not('.d-none')

        if ($errorMessages) {
          $errorMessages.parents('.email-item').not('.d-none').find('.email-input').focus()
        }

        return false
      } else {
        warpEmail()
        warpInvoiceEmail()
      }
    })

    $('#schedule-wrap').on('click', '.btn-confirm-cancel', function() {
      let html = $("#btn-submit-cancel")[0].outerHTML
      let meetId = $(this).data('meet-id');
      let actionType = $(this).data('action-type');
      let isPaidByCard = $(this).data('paid-by-card');
      $("#cancel_reason").val("");
      $("#cancel_type").val("");
      $("#reschedule_reason").val("");
      $("#reschedule_type").val("");
      $(".cancel_reason").addClass("d-none");
      $(".cancel_type").addClass("d-none");
      $(".reschedule_reason").addClass("d-none");
      $(".reschedule_type").addClass("d-none");
      $(".require_check_reschedule_reason").css("display", "none");

      if(actionType == "cancel-type"){
        $("#modal-cancel-confirm .modal-title").text("キャンセル確認")
        $("#modal-cancel-confirm .question").text("購入した情報を本当にキャンセルしますか？")
        if (isPaidByCard ) {
          $("#modal-cancel-confirm .warning").html(
            "※キャンセルする場合は、購入・請求・日程情報が全て削除されます。</br>クレジットカードで決済を行なったため、Payment robot側でも決済取消を行います。"
          )
        } else {
          $("#modal-cancel-confirm .warning").html("※キャンセルする場合は、購入・請求・日程情報が全て削除されます。")
        }
        $(".cancel_reason").removeClass("d-none");
        $(".cancel_type").removeClass("d-none");
      } else {
        $("#modal-cancel-confirm .modal-title").text("リスケ確認")
        $("#modal-cancel-confirm .question").text("購入した情報を本当にリスケしますか？")
        $("#modal-cancel-confirm .warning").text("※リスケする場合は、面談希望中に戻して面談日を変更できます。")
        $(".reschedule_reason").removeClass("d-none");
        $(".reschedule_type").removeClass("d-none");
      }

      html = html.replace('.action_type', `${actionType}`);
      html = html.replace('cancel-type', `${actionType}`);
      html = html.replace('re-schedule-type', `${actionType}`);
      html = html.replace('.meet_id', `${meetId}`);
      html = html.replace(/data-meet-id="([0-9]*)"/, `data-meet-id="${meetId}"`);

      $("#btn-submit-cancel")[0].outerHTML = html
    });

    $('#conversion_is_meet_offline, #conversion_work_place_attribute_id, #conversion_job_id, .conversion_is_married').change(function() {
      calculatePrice();
    });

    $('#conversion_personal_annual_income, #conversion_saving_price').on('input', function() {
      calculatePrice();
    });

    function calculatePrice() {
      let buttonStates = {};
      $(".btn-add-meeting").each(function() {
        buttonStates[$(this).attr('data-btn-id')] = $(this).prop('disabled');
      });

      $(".btn-add-meeting").attr('disabled', true);
      $(".btn-edit").attr('disabled', true);
      $.ajax({
        url: '/admins/price',
        type: 'PATCH',
        dataType: 'json',
        data: convertFormToJSON()
      }).done(function (response) {
        $(".btn-add-meeting").each(function() {
          let buttonId = $(this).attr('data-btn-id');
          if (!buttonStates[buttonId]) {
            $(this).attr('disabled', false);
          }
      });
        $(".btn-edit").attr('disabled', false)
        let conversionPrice = parseInt(response.price).toLocaleString("ja-JP");
        $("#conversion_price").val(conversionPrice)
        $("#conversion_price_real_estate").val(response.price_real_estate)
      })
    }
    $('#modal-cancel-confirm').off('click', '#btn-submit-cancel').on('click', '#btn-submit-cancel', function(e) {
      let meetId = $(this).data('meet-id');
      let actionType = $(this).data('action-type');
      let cancelReason = $("#cancel_reason").val();
      let cancelType = $("#cancel_type").val();
      let rescheduleReason = $("#reschedule_reason").val();

      let rescheduleType = [];
      let isChecked = false;
      if (actionType === "re-schedule-type") {
        $('[name="reschedule_type[]"]:checked').each(function() {
          let id = $(this).data('id'); 
          rescheduleType.push(id); 
          isChecked = true;
        });
      } else {
        isChecked = true;
      }

      if (isChecked == false) {
        $(".require_check_reschedule_reason").css("display", "block");
        return false; 
      }

      $(".trigger-close-modal").trigger('click');
      
      let cancelInterviewStatus = $("input[name=cancel_interview_status]:checked").val();
      $.ajax({
        url: `/admins/meeting/${meetId}`,
        type: 'POST',
        dataType: 'json',
        data: {
          action_type: actionType,
          cancel_reason: cancelReason,
          cancel_type: cancelType,
          reschedule_reason: rescheduleReason,
          reschedule_type: rescheduleType,
          cancel_interview_status: cancelInterviewStatus
        },
      }).done(function (response) {
        if(response.status ==  "OK") {

          if(actionType == "cancel-type"){
            $(".buy-alert.alert-success").text("キャンセル処理が成功しました。")
            $(`.table-asked-company .conversion-companies-id-${response.data.cc_id}`).remove()
          } else {
            $(".buy-alert.alert-success").text("リスケ処理が成功しました。")
          }

          $(".buy-alert.alert-success").removeClass("d-none")
          setTimeout(() => {
            $(".buy-alert.alert-success").addClass("d-none")
            location.reload();

          }, 1500);

          $(`.meet-id-${meetId}`).remove()
        } else {
          if(actionType == "cancel-type"){
            $(".buy-alert.alert-danger").text("キャンセル処理が失敗しました。")
          } else {
            $(".buy-alert.alert-danger").text("リスケ処理が失敗しました。")
          }

          $(".buy-alert.alert-danger").removeClass("d-none")

          setTimeout(() => {
            $(".buy-alert.alert-danger").addClass("d-none")
          }, 3000);

          $(`.meet-id-${meetId}`).find(".btn-confirm-cancel").attr("disabled", false)
        }
      })
    })

    $('.checkbox_reschedule_type').change(function() {
      if($(this).is(':checked')) {
        $(".require_check_reschedule_reason").css("display", "none");
      }
  });

    $('#schedule-wrap').on('click', '.btn-confirm-buy', function() {
      let html = $("#btn-submit-buy")[0].outerHTML
      let btnId = $(this).data('btn-id');

      html = html.replace('.btn_id', `.${btnId}`);
      html = html.replace(/\.([0-9])*/, `.${btnId}`);

      $("#btn-submit-buy")[0].outerHTML = html
      $('input[name="is_send_email"][value="true"]').prop('checked', true);
      $('input[name="is_on_sale"][value="true"]').prop('checked', true);
      $("#discount_amount_group").show();
      $("#admin_comment").val("");
      $("#btn-submit-buy").addClass("disabled");

      let $meetItem = $(this).parents('.meet-item')
      let companyId = $meetItem.find('.meeting_company_id_select2').val()
      let isRealEstate = $meetItem.find('.meeting_company_id_select2 option[value="' + companyId + '"]').data('is-real-estate');
      let conversionPrice = parseFloat($("#conversion_price").val().replace(/,/g, ''));

      $("#price_invoice").val(conversionPrice);
      if (isRealEstate == true || isRealEstate == 'true') {
        $("#price_invoice").val($("#conversion_price_real_estate").val());
      }
      resetPriceAndDiscount();
    });

    $('#modal-buy-confirm #admin_comment').on('keyup', function(e) {
      checkSubmitButtonStatus();
    })

    $('#schedule-wrap').off('click', '.btn-save-schedule').on('click', '.btn-save-schedule', function(e) {
      $(this).attr("disabled", true)
      const $meetItem = $(this).parents('.meet-item')
      $meetItem.find(".btn-confirm-buy").attr("disabled", true)
      const $timeWrap = $meetItem.find('.time-wrap')
      const startDate = $meetItem.find('.start-date-input').val()
      const startHour = $timeWrap.find('.start_hour').val()
      const startMinute = $timeWrap.find('.start_minute').val()
      const endHour = $timeWrap.find('.end_hour').val()
      const endMinute = $timeWrap.find('.end_minute').val()
      const cvID = $timeWrap.find('.cv_id').val()
      const companyID = $meetItem.find('.meeting_company_id_select2').val()
      const isSendEmail = $('input[name=is_send_email]:checked', '#modal-buy-confirm').val()
      const adminComment = $('#modal-buy-confirm #admin_comment').val()
      const priceAfterDiscount = $("#price_after_discount").val();

      $.ajax({
        url: `/admins/meeting`,
        type: 'POST',
        dataType: 'json',
        data: {
          company_id: companyID,
          cv_id: cvID,
          start_date: startDate,
          start_hour: startHour,
          start_minute: startMinute,
          end_hour: endHour,
          end_minute: endMinute,
          is_send_email: isSendEmail,
          admin_comment: adminComment,
          price_after_discount: priceAfterDiscount,
        }
      }).done(function (response) {
        if(response.status ==  "OK") {
          $meetItem.find(".btn-remove-schedule").remove()
          $meetItem.find("input").attr("disabled", true)
          $meetItem.find("select").attr("disabled", true)
          $meetItem.find(".meeting_company_id_select2").select2({disabled: true})
          $meetItem.find(".btn-confirm-cancel").removeAttr('hidden')
          $meetItem.find(".btn-confirm-cancel").attr('data-meet-id', response.data.meeting_id);
          $meetItem.find(".get-content-template").removeAttr('hidden')
          $meetItem.find(".get-content-template").attr('data-meet-id', response.data.meeting_id);
          $meetItem.find(".btn-confirm-buy").remove()
          $meetItem.find(".btn-save-schedule").attr("disabled", true)
          $meetItem.find('.error-message').addClass('d-none')
          $meetItem.find(".open-change-fee").removeAttr('hidden')
          $meetItem.find(".open-change-fee").attr('data-current-fee', response.data.fee);
          $meetItem.find(".open-change-fee").attr('data-invoice-id', response.data.invoice_id);
          $meetItem.find(".open-change-web-url").removeAttr('hidden')
          $meetItem.find(".open-change-web-url").attr('data-meet-id', response.data.meeting_id);
          $meetItem.addClass(`meet-id-${response.data.meeting_id}`)

          $(".buy-alert.alert-success").text("予約確定を成功しました。")
          $(".buy-alert.alert-success").removeClass("d-none")

          setTimeout(() => {
            $(".buy-alert.alert-success").addClass("d-none")
          }, 3000);

        } else {
          $(".buy-alert.alert-danger").text(response.msg)
          $(".buy-alert.alert-danger").removeClass("d-none")

          setTimeout(() => {
            $(".buy-alert.alert-danger").addClass("d-none")
          }, 3000);
          $meetItem.find(".btn-confirm-buy").attr("disabled", false)
          $meetItem.find(".btn-save-schedule").attr("disabled", false)
        }
      })
    })

    $('.btn-add-schedule').off('click').click(function() {
      let scheduleItemHtml = $('#schedule-wrap .meet-item.d-none')[0].outerHTML
      const parternReplaceID = /\[meet_schedule\]\[(.*?)\]/ig
      const partern = /meet_schedule_(.*?)_/ig

      scheduleItemHtml = scheduleItemHtml.replaceAll('d-none', '')
                                         .replaceAll('meet_schedule_default', `meet_schedule`)
                                         .replaceAll('btn-create-meeting', `btn-add-meeting`)
                                         .replaceAll('time-wrap-default', `time-wrap`)
                                         .replaceAll('meet_schedule_btn_id', `${Date.now()}`)
                                         .replaceAll(parternReplaceID, `[meet_schedule][${Date.now()}]`)
                                         .replaceAll(partern, `meet_schedule_${Date.now()}_`)

      $('#schedule-wrap').append(scheduleItemHtml)

      initDatepicker()
      $(".meeting_company_id_select2").last().select2();
    })

    $('#schedule-wrap').on('click', '.btn-remove-schedule', function() {
      $(this).parents('.meet-item').remove()

      checkTimeWrap()

      if (!$('#schedule-wrap').find('.time-wrap').length) {
        $('#cv-edit-form').prepend(`<input type="hidden" name="conversion[meet_schedule][]" id="conversion_meet_schedule_default" value="">`)
      } else {
        $('#cv-edit-form').find('#conversion_meet_schedule_default').remove()
      }
    })

    $('#schedule-wrap').on('change', '.time-wrap select', function() {
      const $timeWrap = $(this).parents('.time-wrap')
      checkTimeInvalid($timeWrap)
    })

    $('#schedule-wrap').on('blur', '.start-date-input', function() {
      if ($(this).val() == '') {
        $(this).addClass('is-invalid')
      } else {
        $(this).removeClass('is-invalid')

        const $meetItem = $(this).parents('.meet-item')
        const $timeWrap = $meetItem.find('.time-wrap')

        checkTimeInvalid($timeWrap)
      }
    })

    $('#schedule-wrap').on('apply.daterangepicker', '.datapicker', function(ev, picker) {
      const $meetItem = $(this).parents('.meet-item')
      const $timeWrap = $meetItem.find('.time-wrap')

      checkTimeInvalid($timeWrap, picker.startDate.format(DATE_FORMAT))
      $(this).val(picker.startDate.format(DATE_FORMAT));
      $(this).removeClass('is-invalid')
    });

    $('#schedule-wrap').on('cancel.daterangepicker', '.datapicker', function(ev, picker) {
      if ($(this).val() == '') {
        $(this).addClass('is-invalid')
      } else {
        $(this).removeClass('is-invalid')
      }
    });

    checkTimeWrap()

    $('#cv-edit-form').off('submit').on('submit', function() {
      let staff_id = $("#conversion_staff_id").val();
      if (staff_id == '' || staff_id == undefined) {
        $('.show-modal-staff-warning').trigger('click');
        return false;
      }
      
      checkTimeWrap()
      if (!formInvalid.every(v => !v)) return false
    })

    $('.btn-send-email').click(function() {
      return false
    })

    // Admin/Client: invoices screen
    $('.charge_start_date').change(function() {
      $('.charge_end_date').attr('min', this.value)
    });

    $('.charge_end_date').change(function() {
      $('.charge_start_date').attr('max', this.value)
    });
    $('.modal_charge_start_date').change(function() {
      $('.modal_charge_end_date').attr('min', this.value)
    });

    $('.modal_charge_end_date').change(function() {
      $('.modal_charge_start_date').attr('max', this.value)
    });

    $('.payment_start_date').change(function() {
      $('.payment_end_date').attr('min', this.value)
    });

    $('.payment_end_date').change(function() {
      $('.payment_start_date').attr('max', this.value)
    });
    $('.modal_payment_start_date').change(function() {
      $('.modal_payment_end_date').attr('min', this.value)
    });

    $('.modal_payment_end_date').change(function() {
      $('.modal_payment_start_date').attr('max', this.value)
    });

    $('.meeting-start-date').change(function() {
      $('.meeting-end-date').attr('min', this.value)
    });

    $('.meeting-end-date').change(function() {
      $('.meeting-start-date').attr('max', this.value)
    });

    $('.cv-start-date').change(function() {
      $('.cv-end-date').attr('min', this.value)
    });

    $('.cv-end-date').change(function() {
      $('.cv-start-date').attr('max', this.value)
    });

    $('.csv-start-date').change(function() {
      $('.csv-end-date').attr('min', this.value)

      if ($('.csv-end-date').val() && $('.csv-end-date').val()) {
        $('.submit-export-csv').attr('disabled', false)
      }
    });

    $('.csv-end-date').change(function() {
      $('.csv-start-date').attr('max', this.value)

      if ($('.csv-end-date').val() && $('.csv-end-date').val()) {
        $('.submit-export-csv').attr('disabled', false)
      }
    });

    $("#article_thumbnail").change(function() {
      if (this.files && this.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $('#img_prev').attr('src', e.target.result)
          $('.thumbnail-preview img').attr('src', e.target.result)
        };

        reader.readAsDataURL(this.files[0]);
      }
    })

    $("#client_avatar").change(function() {
      if (this.files && this.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $('#img_prev_small').attr('src', e.target.result)
        };

        reader.readAsDataURL(this.files[0]);
      }
    })

    $("#company_logo").change(function() {
      if (this.files && this.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $('#img_prev_small').attr('src', e.target.result)
        };

        reader.readAsDataURL(this.files[0]);
      }
    })

    $("#template_images").change(function() {
      $("#img_previews").empty();
    
      if (this.files && this.files.length > 0) {
        Array.from(this.files).forEach(file => {
          var reader = new FileReader();
          reader.onload = function(e) {
            let img = $('<img/>', {
              src: e.target.result,
              class: 'img-preview',
              css: { width: '100px', height: '100px', margin: '5px' }
            });
            $("#img_previews").append(img);
          };
    
          reader.readAsDataURL(file);
        });
      }
    });    

    $("#nav-preview-tab").click(function() {
      $(".article-title-preview").text($("#article_title").val())

      let htmlContent = $('[name="article[content]"]').val()
      $(".content-preview .trix-content").html(htmlContent)
    })

    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    const date = new Date();

    const startDay = new Date(new Date(date.getFullYear(), date.getMonth(), 1) - tzoffset).toISOString().slice(0, 10);
    const endDate = new Date(Date.now() - tzoffset).toISOString().slice(0, 10);

    const startPaymentDay = null
    const endPaymentDate = null

    $('#invoice-default-search').click(function(e) {
      e.preventDefault();

      $("#invoice-search-form input[name='start_date']").val(startDay);
      $("#invoice-search-form input[name='end_date']").val(endDate);
      $("#invoice-search-form input[name='start_payment_date']").val(startPaymentDay);
      $("#invoice-search-form input[name='end_payment_date']").val(endPaymentDate);

      $("#invoice-search-form").submit();
    })

    $('#company-default-search').click(function(e) {
      e.preventDefault();

      $("#company-search-form input[name='start_date']").val(startDay);
      $("#company-search-form input[name='end_date']").val(endDate);
      $("#company-search-form input[name='start_payment_date']").val(startPaymentDay);
      $("#company-search-form input[name='end_payment_date']").val(endPaymentDate);

      $("#company-search-form").submit();
    })

    $('.copy-clipboard').click(function(e) {
      copyToClipboard();
    })

    $(".copy-clipboard").on("mouseout", function() {
      outClipboard();
    })

    $('#max_invoices').on('input', function() {
      var maxInvoices = parseFloat($(this).val());
      var minInvoices = parseFloat($('#min_invoices').val());
  
      if (!isNaN(maxInvoices) && !isNaN(minInvoices)) {
        if (maxInvoices < minInvoices) {
          $(this).val(minInvoices);
        }
      }

      if (maxInvoices < 0) {
        $(this).val(0);
      }
    });
  
    $('#min_invoices').on('input', function() {
      var minInvoices = parseFloat($(this).val());
      var maxInvoices = parseFloat($('#max_invoices').val());
  
      if (!isNaN(minInvoices) && !isNaN(maxInvoices)) {
        if (minInvoices > maxInvoices) {
          $(this).val(maxInvoices);
        }
      }

      if (minInvoices < 0) {
        $(this).val(0);
      }
    });

    $('.show-more-content').click(function(e) {
      e.preventDefault();
      var content = $(this).data('content');
      $('#show-content').html(content);
    })

    $("#company_payment_method").on('change', function(e) {
      if ($(this).val() == "bank_transfer") {
        $(".change-pm-alert").removeClass('d-none')
      } else {
        $(".change-pm-alert").addClass('d-none')
      }
    })

    $('.edit-company-payment-method').on('click', function(e) {
      e.preventDefault();
      $('.card-footer-edit-company-payment-method').removeClass('d-none');
      $('.comapy-payment-method-show').addClass('d-none');
      $('.comapy-payment-method-input').removeClass('d-none');
    });

    $('.edit-company').on('click', function(e) {
      e.preventDefault();
      $('.prefecture-names').hide();
      $('span.select2').show();
      $('.card-footer-edit-company').show();
      $(".toggle-select-all-prefecture").show();
      $('.client_company_show').hide();
      $('.client_company_input').show();
    });
    
    $('.button_search_client').on('click', function(e) {
      e.preventDefault();
      searchClientCompany()
    });

    $('.input_search_client').keypress(function(event) {
      if (event.which === 13) {
        event.preventDefault();
        searchClientCompany()
      }
    });

    $('.sell-csv-start-date').change(function() {
      $('.sell-csv-end-date').attr('min', this.value)
    });

    $('.sell-csv-end-date').change(function() {
      $('.sell-csv-start-date').attr('max', this.value)
    });

    $(".input_number_positive").on('input', function() {
      let value = parseFloat($(this).val());
      if (value < 0) {
        $(this).val(0);
      }
    })
    
    $('#csv_file').change(function() {
      var file = $(this).val();
      var submitBtn = $('.submit-import-csv'); 

      if (file) {
        submitBtn.prop('disabled', false);
      } else {
        submitBtn.prop('disabled', true);
      }
    });

    $(".show-popup-import-csv").on('click', function(e) {
      $("#form-import-csv")[0].reset();
      $(".submit-import-csv").prop('disabled', true);
    });

    $('input[name=is_on_sale]').on('change', function() {
      let val = $(this).val();
      if (val == 'true') {
        $("#discount_amount_group").show();
      } else {
        $("#discount_amount_group").hide();
        resetPriceAndDiscount();
      }
      checkSubmitButtonStatus();
    });
  
    $("#discount_amount").on('input', function() {
      let price_after_discount = parseFloat($("#price_invoice").val().replace(/,/g, ''));
      let discount_amount = parseFloat($(this).val().replace(/,/g, ''));
      $(".discount_amount_error").hide();

      if (discount_amount >= price_after_discount) {
        $(".discount_amount_error").show();
      }

      if (isNaN(discount_amount)) {
        discount_amount = 0;
      }

      price_after_discount = price_after_discount - discount_amount;
      let formatted_price = price_after_discount.toLocaleString("ja-JP");

      $(".price_after_discount").text(formatted_price);
      $("#price_after_discount").val(price_after_discount);

      checkSubmitButtonStatus();
    });

    function resetPriceAndDiscount() {
      let current_price = parseFloat($("#price_invoice").val().replace(/,/g, ''));
      $("#price_after_discount").val(current_price);
      $(".price_after_discount").text(current_price.toLocaleString("ja-JP"));
      $("#discount_amount").val('');
    }
    
    function checkSubmitButtonStatus() {
      let is_on_sale = $('input[name=is_on_sale]:checked').val() == 'true';
      let discount_amount = parseFloat($("#discount_amount").val().replace(/,/g, ''));
      let current_price = parseFloat($("#price_invoice").val().replace(/,/g, ''));
      let btn_submit_buy = $("#btn-submit-buy");
      var adminComment = $("#admin_comment").val();

      if (adminComment.length > 500) {
        $("#admin_comment_error").text("500文字以内で入力してください。")
        $("#admin_comment_error").removeClass("d-none")
      } else {
        $("#admin_comment_error").addClass("d-none")
      }

      if (is_on_sale && (isNaN(discount_amount) || discount_amount >= current_price) || adminComment.length > 500) {
        btn_submit_buy.addClass("disabled")
      } else {
        btn_submit_buy.removeClass("disabled")
      }
    }

    function searchClientCompany() {
      let inputSearch = $('.input_search_client').val();
      let companyId = $("#company_id").val();
      $.ajax({
        url: `/admins/companies/client_list`,
        type: 'get',
        dataType: 'html',
        data: {
          company_id: companyId,
          input_search: inputSearch,
        }
      }).done(function (response) {
        $("#render_client").empty();
        $("#render_client").html(response);
      })
    }

    function checkEmailInvalid(email, $emailInput) {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const isInvalid = !emailRegex.test(String(email).toLowerCase());

      const $emailItem = $emailInput.parents('.email-item')
      const $emailItems = $('#emails-wrap').find('.email-item').not($emailItem).not('.d-none')

      let isDuplicate = false
      let hasError = false

      $emailItems.each(function() {
        const $otherEmailInput = $(this).find('.email-input')

        if(checkDuplicateEmail(email, $otherEmailInput.val())) {
          isDuplicate = true
        }
      })

      if (isInvalid) {
        $emailItem.find('.error-message').removeClass('d-none')
        $emailItem.find('.error-message').find('span').html('メールアドレスの形式は正しくありません。')
        hasError = true
      } else if (isDuplicate) {
        $emailItem.find('.error-message').removeClass('d-none')
        $emailItem.find('.error-message').find('span').html('メールアドレスがすでに登録されています。')
        hasError = true
      } else {

        $emailItem.find('.error-message').addClass('d-none')
        hasError = false
      }

      formInvalid.push(hasError)
    }

    function convertFormToJSON() {
      let $id = "#cv-edit-form";
      if ($("#cv-new-form").length == 1) {
        $id = "#cv-new-form";
      }
      return $($id)
        .serializeArray()
        .reduce(function (json, { name, value }) {
          json[name] = value;
          return json;
        }, {});
    }

    function checkEmailWrap() {
      formInvalid = []

      if (!$('#emails-wrap').find('.email-item').length) {
        formInvalid.push(true)
      } else {
        formInvalid.push(false)
      }

      $('#emails-wrap').find('.email-item:not(.d-none) .email-input').each(function() {
        const $this = $(this)
        const emailVal = $this.val()

        checkEmailInvalid(emailVal, $this)

        if ($this.val() == '') {
          formInvalid.push(true)
        }
      })
    }

    function checkDuplicateEmail(email1, email2) {
      return String(email1).toLowerCase() == String(email2).toLowerCase();
    }

    function checkEmailPresent() {
      const $emailItems = $('#emails-wrap').find('.email-item').not('.d-none')

      if ($emailItems.length > 1) {
        $emailItems.each(function() {
          $(this).find('.btn-remove-email').removeClass('disabled')
        })
      } else {
        $emailItems.find('.btn-remove-email').addClass('disabled')
        formInvalid.push(true)
      }
    }

    function warpEmail() {
      const $emailItems = $('#emails-wrap').find('.email-item').not('.d-none')
      const listEmail = []

      $emailItems.each(function() {
        const email = $(this).find('.email-input').val().trim().toLowerCase()
        listEmail.push(email)
      })

      const listStringEmail = listEmail.join(",")

      $('#emails-wrap').find('.company-emails').val(listStringEmail)
    }

    function checkTimeInvalid($timeWrap, dateString = null) {
      const $meetItem = $timeWrap.parents('.meet-item')
      const $startDateInput = dateString ?? $meetItem.find('.start-date-input').val()
      const startHour = $timeWrap.find('.start_hour').val()
      const startMinute = $timeWrap.find('.start_minute').val()
      const endHour = $timeWrap.find('.end_hour').val()
      const endMinute = $timeWrap.find('.end_minute').val()
      const startTime = parseInt(startHour) * 60 + parseInt(startMinute)
      const endTime = parseInt(endHour) * 60 + parseInt(endMinute)
      const diffTime = endTime - startTime
      const $meetItems = $('#schedule-wrap').find('.meet-item').not($meetItem).not('.d-none')

      let isDuplicate = false
      let hasError = false

      $meetItems.each(function() {
        const $timeWrapOther = $(this).find('.time-wrap')

        const startHourOther = $timeWrapOther.find('.start_hour').val()
        const endHourOther = $timeWrapOther.find('.end_hour').val()
        const isSameDate = $startDateInput == $(this).find('.start-date-input').val()

        const startIsBetween = startHourOther <= startHour &&  startHour < endHourOther
        const endIsBetween = startHourOther < endHour && endHour <= endHourOther

        if (isSameDate && (startIsBetween || endIsBetween)) {
          isDuplicate = true
        }
      })

      if(diffTime == 60) {
        $meetItem.find('.error-message').addClass('d-none')
        hasError = false

        if (isDuplicate) {
          $meetItem.find('.error-message').removeClass('d-none')
          $meetItem.find('.error-message').find('span').html('他の時間と重なっています。')
          hasError = true
        } else {
          $meetItem.find('.error-message').addClass('d-none')
          hasError = false
        }
      } else if (diffTime <= 0) {
        $meetItem.find('.error-message').removeClass('d-none')
        $meetItem.find('.error-message').find('span').html('1時間単位で追加してください。')
        hasError = true
      } else {
        $meetItem.find('.error-message').removeClass('d-none')
        $meetItem.find('.error-message').find('span').html('1時間単位で分けるように、もう一度入力してください。')
        hasError = true
      }

      formInvalid.push(hasError)

      if((!hasError) && $meetItem.find('.start-date-input').val() != ''){
        $meetItem.find('.btn-confirm-buy').attr("disabled", false)
      } else {
        $meetItem.find('.btn-confirm-buy').attr("disabled", true)
      }

      return hasError
    }

    function checkTimeWrap() {
      formInvalid = []

      if (!$('#schedule-wrap').find('.time-wrap').length) {
        formInvalid.push(false)
      }

      $('#schedule-wrap').find('.meet-item:not(.d-none) .start-date-input').each(function() {
        if ($(this).val() == '') {
          $(this).addClass('is-invalid')
          formInvalid.push(true)
        } else {
          $(this).removeClass('is-invalid')

          const $meetItem = $(this).parents('.meet-item')
          const $timeWrap = $meetItem.find('.time-wrap')
          checkTimeInvalid($timeWrap)
        }
      })
    }

    function initDatepicker() {
      $('.datapicker').daterangepicker({
        singleDatePicker: true,
        showDropdowns: false,
        autoUpdateInput: false,
        autoApply: true,
        minDate:new Date(),
        locale: {
          format: DATE_FORMAT,
        },
        maxYear: parseInt(moment().format('YYYY'), 10)
      });

      $('.common-datepicker').daterangepicker({
        useCurrent: false,
        singleDatePicker: true,
        showDropdowns: false,
        autoApply: true,
        locale: {
          format: DATE_FORMAT,
        },
      });
    }

    function copyToClipboard() {
      var copyText = $("#company_url");
      var tooltip = $("#company-tooltip");

      navigator.clipboard.writeText(copyText.text());
      tooltip.html("Copied: " + copyText.text());
    }

    function outClipboard() {
      var tooltip = $("#company-tooltip");
      tooltip.html("Copy to clipboard");
    }

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const targetId = entry.target.id;
          // Check if the content has already been loaded
          if (!$(`#${targetId}`).html()) {
            $(".loading-text").text('データ取得中です。');
            $("#loading-overlay").css('display', 'flex');
            const urlParams = new URLSearchParams(window.location.search);
            const created_at_from = urlParams.get('created_at_from');
            const created_at_to = urlParams.get('created_at_to');
            
            // Ajax call to fetch and append content
            $.ajax({
              url: '/admins/dashboard/append_content',
              method: 'GET',
              data: { target: targetId, created_at_from, created_at_to },
              success: function(response) {
                // Append the content to the target element
                $(`#${targetId}`).html(response);
                $("#loading-overlay").css('display', 'none');
              }
            });
          }
    
          // Stop observing the element after content is loaded
          observer.unobserve(entry.target);
        }
      });
    });
    
    // Add elements to observe
    ['#manager_sale_cv', '#support_cv', '#support_cv_month', '#support_staff_sale_cv', '#staff_sales'].forEach(elementId => {
      const element = document.querySelector(elementId);
      if (element) {
        observer.observe(element);
      }
    });

    $('#line_account_id').select2({
      width: "100%",
      allowClear: true,
      placeholder: '',
      language: {
        inputTooShort: function(args) {
            return "";
        }
      },
      ajax: {
        url: '/admins/get-line-accounts',
        delay: 500,
        data: function (params) {
          var query = {
            display_name_cont: params.term,
          };
       
          return query;
        },
        processResults: function (data) {
          return {
            results: data.data
          };
        }
      },
      minimumInputLength: 1,
      templateResult: function (option) {
        if (!option.id) {
          return option.text;
        }
  
        var ob = '<img class="image-line-account" src="' + option.avatar_url + '" />' + option.text;
        return ob;
      },
      templateSelection: function (repo) {
        return repo.text;
      },
      escapeMarkup: function (m) {
        return m;
      }
    });
    
    $(".open-modal-line-account").on('click', function(e) {
      let currentLineAccountId = $(this).data('line-account-id');
      let lineDisplayName = $(this).data('line-display-name');
      
      var newOption = new Option(lineDisplayName, currentLineAccountId, true, true);
      $('#line_account_id').append(newOption).val(currentLineAccountId).trigger('change');

      $("#select2-line_account_id-container").html(lineDisplayName);

      let lineAccountId = $("#line_account_id").val();
      let selectClear = $(".select2-selection__clear");
      selectClear.css('display', lineAccountId ? 'block' : 'none');
    });

    $(".btn-add-line-account").on('click', function(e) {
      let lineAccountId = $("#line_account_id").val();
      let messageConfirm = "LINEアカウントを連携していません。確定しますか？"
      if (lineAccountId) {
        let displayName = $('#select2-line_account_id-container').text();
        messageConfirm = `${displayName}さんへ連携しても良いですか？`;
      }
      $(".content-confirm-line-account").text(messageConfirm);
      $(".trigger-modal-line-account").trigger("click");
    });

    $(".btn-confirm-line-account").on('click', function(e) {
      $('#form-line-account').submit();
      $("#modal-confirm-line-account").fadeOut(500, function() {
        $(".modal-backdrop").remove();
      });
    });

    $("[data-tag]").on("click", function() {
      var tag = $(this).data("tag");
      appendTagToTextarea(tag);
    });
  
    function appendTagToTextarea(tag) {
      var textarea = $("#template_content");
      var currentContent = textarea.val();
      var cursorPosition = textarea[0].selectionStart;
      var newContent =
        currentContent.substring(0, cursorPosition) +
        "[" + tag + "]" +
        currentContent.substring(cursorPosition);
  
      textarea.val(newContent);
    }

    $(".confirm-delete-template").on("click", function() {
      var form = $(this).closest('form');
      $(this).closest('.fade').fadeOut(500, function() {
        $(".modal-backdrop").remove();
      });

      $.ajax({
        url: form.attr("action"),
        type: 'delete',
        data: form.serialize(),
        dataType: "json",
        success: function(response) {
          location.reload();
        },
        error: function(error) {
          console.error("Error deleting template", error);
          location.reload();
        }
      });
    });

    $(".btn-confirm-send-message-line").on("click", function() {
      $("#modal-confirm-send-message-line").fadeOut(500, function() {
        $(".modal-backdrop").remove();
      });
      $('#form-send-message-line').submit();
    });

    $(".admin-cv-open-modal").on("click", function() {
      $(".btn-admin-cv-modal-confirm").css("display", "none");
      $("#template_title").val("");
      $("#template_content").val("");
      $("#img_previews").empty();

      $(".btn-admin-cv-modal-template").prop("disabled", true);
      
      let retrievedData = sessionStorage.getItem('adminCvs');

      let cvsObject = JSON.parse(retrievedData);
      $(".message-warning-choose-cv").text("案件選択が必須です。");

      if (cvsObject != null && cvsObject.cvIds.length != 0) {
        let cvIds = cvsObject.cvIds;
        $(".message-warning-choose-cv").text("選択した案件にLINEメッセージを一括送信しますか？");
        $(".btn-admin-cv-modal-confirm").css("display", "block");
        getTemplates(cvIds);
      }

      $(".show-admin-cv-modal-confirm").trigger("click");
    });

    $('#select-all-cv').on('change', function() {
      if ($(this).prop('checked')) {
        $('.individual-cv').prop('checked', true);
        let allCvIds = $('#conversion-ids-data').data('conversion-ids');

        if (allCvIds) {
          let cvsObject = {
            cvIds: allCvIds
          };
      
          sessionStorage.setItem('adminCvs', JSON.stringify(cvsObject));
        }
      } else {
        $('.individual-cv').prop('checked', false);
        sessionStorage.removeItem('adminCvs');
      }
    });
  
    $('.individual-cv').on('change', function() {
      let retrievedData = sessionStorage.getItem('adminCvs');
      let cvsObject;
    
      if (retrievedData) {
        cvsObject = JSON.parse(retrievedData);
      } else {
        cvsObject = {
          cvIds: []
        };
      }
    
      let cvId = parseInt($(this).val());
      let allCvIds = $('#conversion-ids-data').data('conversion-ids');

      if ($(this).prop('checked')) {
        cvsObject.cvIds.push(cvId);
      } else {
        cvsObject.cvIds = cvsObject.cvIds.filter(id => id != cvId);
      }

      if (cvsObject.cvIds.length == allCvIds.length) {
        $('#select-all-cv').prop('checked', true);
      } else {
        $('#select-all-cv').prop('checked', false);
      }
    
      sessionStorage.setItem('adminCvs', JSON.stringify(cvsObject));
    });
    
  
    $(".btn-admin-cv-modal-template").on("click", function() {
      let retrievedData = sessionStorage.getItem('adminCvs');
      if (!retrievedData) {
        return;
      }

      let cvsObject = JSON.parse(retrievedData);
      let cvIds = cvsObject.cvIds;

      let message = $("#template_content").val();
      let templateId = $("#template_title").val();
      let imageUrls = $('.image-checkbox:checked').map(function() {
        return $(this).val();
      }).get();
      
      sendMessageToMultipleLineAccounts(cvIds, message, templateId, imageUrls)
    });
  
    $("#template_title").on("change", function() {
      let templateId = $(this).val();
    
      let selectedTemplate = templates.find(function(template) {
        return template.id == templateId;
      });
    
      if (selectedTemplate) {
        $("#template_content").val(selectedTemplate.content);
        $("#img_previews").empty();
        if (selectedTemplate.images_url.length > 0) {
          selectedTemplate.images_url.forEach(url => {
            let img = $('<img/>', {
              src: url,
              class: 'img-preview',
              css: { width: '100px', height: '100px', margin: '5px' }
            });
            let checkbox = $('<input/>', {
              type: 'checkbox',
              class: 'image-checkbox',
              name: 'image_urls[]',
              value: url,
              checked:  true
            });
            let container = $('<div/>', {
              class: 'd-inline-grid'
            });
            container.append(img);
            container.append(checkbox);
            $("#img_previews").append(container);
          })
        } 
      }
      toggleBtnSendMessageLine();
    });
    
    $("#template_content").on("input", function() {
      toggleBtnSendMessageLine();
    });
    
    $("#btn-admin-search-cv").on("click", function() {
      sessionStorage.removeItem('adminCvs');
    });

    adminCvsLoad();

    function adminCvsLoad() {
      let queryString = window.location.search;
      if (!$('#admin-cvs').length || queryString === '') {
        sessionStorage.removeItem('adminCvs');
        return;
      }
     
      let retrievedData = sessionStorage.getItem('adminCvs');
      if (!retrievedData) {
        return;
      }
      let parsedData = JSON.parse(retrievedData);
    
      let allCvIds = $('#conversion-ids-data').data('conversion-ids');
      if (parsedData.cvIds.length == allCvIds.length) {
        $('#select-all-cv').prop('checked', true);
      }
      $('.individual-cv').each(function() {
        let cvId = parseInt($(this).val()); 
        if (parsedData.cvIds.includes(cvId)) {
          $(this).prop('checked', true);
        }
      })
    }

    function getTemplates(conversion_ids) {
      $.ajax({
        url: '/admins/get-templates',
        type: 'get',
        data: {conversion_ids},
        dataType: "json",
        success: function(response) {
          templates = response.templates
    
          $("#template_title").empty();
    
          $("#template_title").append($('<option>', {
            value: '',
            text: ''
          }));
    
          templates.forEach(function(template) {
            $("#template_title").append($('<option>', {
              value: template.id,
              text: template.title
            }));
          });
        },
        error: function(error) {
          console.error("Error ", error);
        }
      });
    }
    
    function toggleBtnSendMessageLine() {
      let messageLineValue = $("#template_content").val().trim();
    
      if (!messageLineValue) {
        $(".btn-admin-cv-modal-template").prop("disabled", true);
      } else {
        $(".btn-admin-cv-modal-template").prop("disabled", false);
      }
    }
    
    function sendMessageToMultipleLineAccounts(cvIds, message, templateId, imageUrls) {
      $.ajax({
        url: '/admins/send-message-to-multiple-accounts',
        type: 'post',
        data: {
          cv_ids: cvIds,
          message_line: message,
          template_id: templateId,
          image_urls: imageUrls
        },
        dataType: "json",
        beforeSend: function() {
          $(".loading-text").text('送信中');
          $("#loading-overlay").css('display', 'flex');
        },
        success: function(response) {
          $("#loading-overlay").css('display', 'none');
          sessionStorage.removeItem('adminCvs');
          $("html, body").animate({ scrollTop: 0 }, "slow");
          location.reload();
        },
        error: function(error) {
          $('#loading').addClass('hide')
          $('#loading').removeClass('show')
          console.error("Error ", error);
          location.reload();
        }
      });
    }

    $('#notification_start_date').change(function() {
      $('#notification_end_date').attr('min', this.value)
    });

    $('#notification_end_date').change(function() {
      $('#notification_start_date').attr('max', this.value)
    });

    CKEDITOR.replace('editor', {
      language: 'ja',
      toolbarGroups: [
        { name: 'basicstyles', groups: ['basicstyles'] },
        { name: 'colors', groups: ['colors'] },
      ],
    });

    $(".get-content-template-client").on("click", function() {
      let clientId = $(this).data('client-id');
      let displayName = $(this).data('display-name');

      $(".message-line-to-client").text(`${displayName}さんへメッセージを送信しますか？`);
      $("#client_id").val(clientId);
    });

    $(".open-modal-line-account-client").on('click', function(e) {
      let currentLineAccountId = $(this).data('line-account-client-id');
      let lineDisplayName = $(this).data('line-account-client-display-name');
      
      var newOption = new Option(lineDisplayName, currentLineAccountId, true, true);
      $('#line_account_client_id').append(newOption).val(currentLineAccountId).trigger('change');

      $("#select2-line_account_client_id-container").html(lineDisplayName);

      let lineAccountId = $("#line_account_client_id").val();
      let selectClear = $(".select2-selection__clear");
      selectClear.css('display', lineAccountId ? 'block' : 'none');
    });

    $('#line_account_client_id').select2({
      width: "100%",
      allowClear: true,
      placeholder: '',
      language: {
        inputTooShort: function(args) {
            return "";
        }
      },
      ajax: {
        url: '/admins/get-line-account-clients',
        delay: 500,
        data: function (params) {
          var query = {
            display_name_cont: params.term,
          };
       
          return query;
        },
        processResults: function (data) {
          return {
            results: data.data
          };
        }
      },
      minimumInputLength: 1,
      templateResult: function (option) {
        if (!option.id) {
          return option.text;
        }
  
        var ob = '<img class="image-line-account" src="' + option.avatar_url + '" />' + option.text;
        return ob;
      },
      templateSelection: function (repo) {
        return repo.text;
      },
      escapeMarkup: function (m) {
        return m;
      }
    });

    $(".btn-add-line-account-client").on('click', function(e) {
      let lineAccountId = $("#line_account_client_id").val();
      let messageConfirm = "LINEアカウントを連携していません。確定しますか？"
      if (lineAccountId) {
        let displayName = $('#select2-line_account_client_id-container').text();
        messageConfirm = `${displayName}さんへ連携しても良いですか？`;
      }
      $(".content-confirm-line-account").text(messageConfirm);
    });

    var matchingTemplates = [];

    $('#select-all-client').on('change', function() {
      if ($(this).prop('checked')) {
        $('.individual-client').prop('checked', true);
      } else {
        $('.individual-client').prop('checked', false);
      }
    });

    $('.individual-client').on('change', function() {
      let allClient = $('.individual-client').length
      let clientChecked = $('.individual-client:checked').length
      if (allClient == clientChecked) {
        $('#select-all-client').prop('checked', true);
      } else {
        $('#select-all-client').prop('checked', false);
      }
    });

    $(".btn-line-multiple-client-matching").on("click", function() {
      let client_ids = [];
      $('.individual-client').each(function() {
        if ($(this).is(':checked')) {
          client_ids.push($(this).val());
        }
      });

      if (client_ids.length == 0) {
        $(".message-warning-choose-client").text("支店を選択する必須です。");
        $(".btn-confirm-choose-multiple-client-matching").css("display", "none");
      } else {
        $("#client_ids").val(client_ids.join(','));
        $(".message-warning-choose-client").text("選択した支店にLINEメッセージを一括送信しますか？");
        $(".btn-confirm-choose-multiple-client-matching").css("display", "block");
      }
    });

    $(".btn-confirm-choose-multiple-client-matching").on("click", function() {
      let conversion_id = $("#conversion_id").val();
      let client_ids = $("#client_ids").val();
      let data = {
        conversion_id,
        client_ids
      }
      getTemplateMatching(data);
    });

    $("#template_id_matching").on("change",  function() {
      let templateId = $(this).val();
    
      let selectedTemplate = matchingTemplates.find(function(template) {
        return template.id == templateId;
      });
    
      if (selectedTemplate) {
        $("#message_line_matching").val(selectedTemplate.content);
        $("#img_previews_template_matching").empty();
        if (selectedTemplate.images_url.length > 0) {
          selectedTemplate.images_url.forEach(url => {
            let img = $('<img/>', {
              src: url,
              class: 'img-preview',
              css: { width: '100px', height: '100px', margin: '5px' }
            });
            let checkbox = $('<input/>', {
              type: 'checkbox',
              class: 'image-checkbox',
              name: 'image_urls_template_matching[]',
              value: url,
              checked:  true
            });
            let container = $('<div/>', {
              class: 'd-inline-grid'
            });
            container.append(img);
            container.append(checkbox);
            $("#img_previews_template_matching").append(container);
          })
        } 
      }
      toggleDisableBtnMatchingMessageLine();
    });
    
    $("#message_line_matching").on("input", function() {
      toggleDisableBtnMatchingMessageLine();
    });
    
    $(".btn-modal-template-matching").on("click", function() {
      $("#modal-template-matching").fadeOut(500, function() {
        $(".modal-backdrop").remove();
      });
    });

    function getTemplateMatching(data) {
      $("#template_id_matching").val("");
      $("#message_line_matching").val("");
      $("#img_previews_template_matching").empty();
      $(".btn-modal-template-matching").prop("disabled", true);
      
      $.ajax({
        url: '/admins/get-templates',
        type: 'get',
        data: data,
        dataType: "json",
        success: function(response) {
          matchingTemplates = response.templates
          $("#template_id_matching").empty();
    
          $("#template_id_matching").append($('<option>', {
            value: '',
            text: ''
          }));
    
          matchingTemplates.forEach(function(template) {
            $("#template_id_matching").append($('<option>', {
              value: template.id,
              text: template.title
            }));
          });
        },
        error: function(error) {
          console.error("Error ", error);
        }
      });
    }

    function toggleDisableBtnMeetingUrl() {
      let meet_url = $("#new_url").val().trim();
    
      if (!meet_url) {
        $(".btn-change-google-url").prop("disabled", true);
      } else {
        $(".btn-change-google-url").prop("disabled", false);
      }
    }

    $(document).on("click", ".open-change-web-url", function() {
      let meet_id = $(this).data('meet-id');
      let current_url = $(this).data('current-url');

      $("#meet_id").val(meet_id);
      $("#new_url").val(current_url);
    })
    
    $("#new_url").on("input", function() {
      toggleDisableBtnMeetingUrl();
    });

    function toggleDisableBtnMatchingMessageLine() {
      let messageLineValue = $("#message_line_matching").val().trim();
    
      if (!messageLineValue) {
        $(".btn-modal-template-matching").prop("disabled", true);
      } else {
        $(".btn-modal-template-matching").prop("disabled", false);
      }
    }

    $(".line_status").on("change", function() {
      let client_id = $(this).data('client-id');
      let conversion_id = $("#conversion_id").val();
      let line_status = $(this).val();
      
      let data = {
        client_id,
        conversion_id,
        line_status
      }
      $.ajax({
        url: '/admins/update-line-status-matching',
        type: 'post',
        data: data,
        dataType: "json",
        success: function(response) {
          $(".buy-alert.alert-success").text("更新しました。")
          $(".buy-alert.alert-success").removeClass("d-none")

          setTimeout(() => {
            $(".buy-alert.alert-success").addClass("d-none")
          }, 3000);
        },
        error: function(error) {
          console.error("Error ", error);
        }
      });
    })

    $(".call_status").on("change", function() {
      let client_id = $(this).data('client-id');
      let conversion_id = $("#conversion_id").val();
      let call_status = $(this).val();

      let data = {
        client_id,
        conversion_id,
        call_status
      }
      $.ajax({
        url: '/admins/update-call-status-matching',
        type: 'post',
        data: data,
        dataType: "json",
        success: function(response) {
          $(".buy-alert.alert-success").text("更新しました。")
          $(".buy-alert.alert-success").removeClass("d-none")

          setTimeout(() => {
            $(".buy-alert.alert-success").addClass("d-none")
          }, 3000);
        },
        error: function(error) {
          console.error("Error ", error);
        }
      });
    })

    $('input[name="conversion[has_current_insurance]"]').change(function() {
      if ($('#conversion_has_current_insurance_true').is(':checked')) {
        $('.current_insurance_input').show();
      } else {
        $('.current_insurance_input').hide();
      }
      $("#conversion_current_insurance").val("");
    });

    if ($('#bar_cv').length > 0) {
      $('#bar_cv_bought, #bar_cv_not_bought, #bar_cv_meeting, #bar_cv_reject, #bar_cv_request_interview').text("");
      $(".bar_cv_data").addClass('hide');
      let cv_bought = $('#bar_cv_bought').length > 0;
      let cv_not_bought = $('#bar_cv_not_bought').length > 0;
      let cv_meeting = $('#bar_cv_meeting').length > 0;
      let cv_reject = $('#bar_cv_reject').length > 0;
      let cv_request_interview = $('#bar_cv_request_interview').length > 0;

      let data = {
        cv_bought,
        cv_not_bought,
        cv_meeting,
        cv_reject,
        cv_request_interview
      }
      $.ajax({
        url: '/admins/get-data-cv-bar',
        type: 'get',
        data: data,
        dataType: "json",
        success: function(response) {
          let result = response.result
          $(".bar_cv_data").removeClass('hide');
          if (cv_bought) {
            $('#bar_cv_bought').text(result.cv_bought);
          }
          if (cv_not_bought) {
            $('#bar_cv_not_bought').text(result.cv_not_bought);
          }
          if (cv_meeting) {
            $('#bar_cv_meeting').text(result.cv_meeting);
          }
          if (cv_reject) {
            $('#bar_cv_reject').text(result.cv_reject);
          }
          if (cv_request_interview) {
            $('#bar_cv_request_interview').text(result.cv_request_interview);
          }
          $("span").removeClass("spinner-border");
        },
        error: function(error) {
          console.error("Error ", error);
        }
      });
    }

    $("#new_fee").on('input', function() {
      let new_fee = parseFloat($(this).val().replace(/,/g, ''));

      $(".btn-change-fee").prop("disabled", false);

      if (new_fee == 0) {
        $(this).val("");
      }
      if (isNaN(new_fee) || new_fee <= 0) {
        $(".btn-change-fee").prop("disabled", true);
      }
    });

    $('.btn-add-invoice-email').off('click').click(function() {
      let emailItemHtml = $('#invoice-emails-wrap .email-item.d-none')[0].outerHTML

      emailItemHtml = emailItemHtml.replaceAll('d-none', '')
      $('#invoice-emails-wrap').append(emailItemHtml)

      checkInvoiceEmailPresent()
    })

    function checkInvoiceEmailPresent() {
      const $emailItems = $('#invoice-emails-wrap').find('.email-item').not('.d-none')

      if ($emailItems.length > 1) {
        $emailItems.each(function() {
          $(this).find('.btn-remove-invoice-email').removeClass('disabled')
        })
      } else {
        $emailItems.find('.btn-remove-invoice-email').addClass('disabled')
        formInvalid.push(true)
      }
    }

    $('#invoice-emails-wrap').on('click', '.btn-remove-invoice-email', function() {
      $(this).parents('.email-item').remove()

      checkInvoiceEmailPresent()
    })

    function checkInvoiceEmailWrap() {
      formInvalid = []

      if (!$('#invoice-emails-wrap').find('.email-item').length) {
        formInvalid.push(true)
      } else {
        formInvalid.push(false)
      }

      $('#invoice-emails-wrap').find('.email-item:not(.d-none) .email-input').each(function() {
        const $this = $(this)
        const emailVal = $this.val()

        checkInvoiceEmailInvalid(emailVal, $this)

        if ($this.val() == '') {
          formInvalid.push(true)
        }
      })
    }

    function warpInvoiceEmail() {
      const $emailItems = $('#invoice-emails-wrap').find('.email-item').not('.d-none')
      const listEmail = []

      $emailItems.each(function() {
        const email = $(this).find('.email-input').val().trim().toLowerCase()
        listEmail.push(email)
      })

      const listStringEmail = listEmail.join(",")

      $('#invoice-emails-wrap').find('.company-invoice-emails').val(listStringEmail)
    }

    $('#invoice-emails-wrap').on('blur', '.email-input', function() {
      checkInvoiceEmailInvalid($(this).val(), $(this))
    })

    function checkInvoiceEmailInvalid(email, $emailInput) {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const isInvalid = !emailRegex.test(String(email).toLowerCase());

      const $emailItem = $emailInput.parents('.email-item')
      const $emailItems = $('#invoice-emails-wrap').find('.email-item').not($emailItem).not('.d-none')

      let isDuplicate = false
      let hasError = false

      $emailItems.each(function() {
        const $otherEmailInput = $(this).find('.email-input')

        if(checkDuplicateEmail(email, $otherEmailInput.val())) {
          isDuplicate = true
        }
      })

      if (isInvalid) {
        $emailItem.find('.error-message').removeClass('d-none')
        $emailItem.find('.error-message').find('span').html('メールアドレスの形式は正しくありません。')
        hasError = true
      } else if (isDuplicate) {
        $emailItem.find('.error-message').removeClass('d-none')
        $emailItem.find('.error-message').find('span').html('メールアドレスがすでに登録されています。')
        hasError = true
      } else {

        $emailItem.find('.error-message').addClass('d-none')
        hasError = false
      }

      formInvalid.push(hasError)
    }

    $('#csv_file').change(function() {
      var file = $(this).val();
      var submitBtn = $('.btn-import-csv-company'); 

      if (file) {
        submitBtn.prop('disabled', false);
      } else {
        submitBtn.prop('disabled', true);
      }
    });

    $(".btn-confirm-email").on("click", function() {
      var form = $(this).parents('form');
      var subject = form.find('.subject-field').val().trim();
      var email = $('#invoice_email_select').val().toString();
      let isShowEmailField = $("#invoice_email_group").is(":visible");
      var body = form.find('.body-field').val().trim();
      if (subject === '' || body === '' || (email === '' && isShowEmailField)) {
        if (subject === '') {
          form.find('.subject-warning').text("件名は必須項目です。");
        }
        if (email === '' && isShowEmailField) {
          form.find('.email-warning').text("送信先は必須項目です。");
        }
        if (body === '') {
          form.find('.body-warning').text("本文は必須項目です。");
        }
      }
      else {
        $(".btn-sent-email").show();
        $('.btn-confirmed-email').trigger('click');
        let messageConfirm = "請求書を一括送信しますか？"
        if (email != '') {
          messageConfirm = email + "へ請求書を送付してもよろしいでしょうか？";
        }
        $(".sent-email-body").text(messageConfirm)
      }
    });

    $(".subject-field").on("input", function() { 
      let val = $(this).val().trim();
      if (val != '') {
        $('.subject-warning').text("");
      }
    })

    $(".body-field").on("input", function() { 
      let val = $(this).val().trim();
      if (val != '') {
        $('.body-warning').text("");
      }
    })

    $('#invoice_email_select').on('change', function() {
      var selectedValues = $(this).val();
      if (selectedValues.length > 0) {
        $('.email-warning').text("");
      }
    });

    $(".open-modal-confirm-send-mail").on("click", function() {
      resetFormInvoiceMonthTemplate();
      $("#invoice_email_group").show();
      $(".confirm-email-form").each(function() {
        this.reset();
      });
      $('#invoice_email_select').empty();
      let invoice_month_id = $(this).data('invoice-month-id')
      $("#invoice_month_ids").val(invoice_month_id)
      getTemplateInvoiceMonth(invoice_month_id);
    })

    function getTemplateInvoiceMonth(invoice_month_id) {
      $.ajax({
        url: '/admins/invoice-months/mail-template',
        type: 'POST',
        data: {
          invoice_month_id
        },
        dataType: "json",
        beforeSend: function() {
          $(".loading-text").text('送信中');
          $("#loading-overlay").css('display', 'flex');
        },
        success: function(response) {
          let emails = response.data.emails
          $.each(emails, function(index, email) {
            $('#invoice_email_select').append(new Option(email, email));
          });
        
          $('#invoice_email_select').val(emails).trigger('change');
          $(".body-field").val(response.data.body)
          $("#loading-overlay").css('display', 'none');
        },
        error: function(error) {
          $("#loading-overlay").css('display', 'none');
        }
      });
    }

    $(".modal-send-multiple-company").on('click', function() {
      resetFormInvoiceMonthTemplate();
      let retrievedData = sessionStorage.getItem('adminInvoiceMonths');
      let parsedData = JSON.parse(retrievedData);
      if (!retrievedData || parsedData.invoiceMonthIds.length == 0) {
        $(".btn-confirmed-email").trigger('click');
        $(".sent-email-body").text("案件選択が必須です。")
        $(".btn-sent-email").hide();
        return;
      }
      $("#invoice_email_group").hide();
      $(".btn-modal-invoice-month-confirm").trigger('click');
      let invoice_month_id = null;
      if (parsedData.invoiceMonthIds.length == 1) {
        $("#invoice_email_group").show();
        invoice_month_id = parsedData.invoiceMonthIds[0]
      }

      $("#invoice_month_ids").val(parsedData.invoiceMonthIds)
      getTemplateInvoiceMonth(invoice_month_id);
    })

    function resetFormInvoiceMonthTemplate() {
      $('.email-warning').text("");
      $('.subject-warning').text("");
      $('.body-warning').text("");
      $('#invoice_email_select').empty();
      $(".subject-field").val("");
      $(".body-field").val("");
    }
    $('#select-all-invoice-month').on('change', function() {
      if ($(this).prop('checked')) {
        $('.select-invoice-month').prop('checked', true);
        let allInvoiceMonthIds = $('#invoice-month-ids-data').data('invoice-month-ids');

        if (allInvoiceMonthIds) {
          let invoiceMonthObject = {
            invoiceMonthIds: allInvoiceMonthIds
          };
      
          sessionStorage.setItem('adminInvoiceMonths', JSON.stringify(invoiceMonthObject));
        }
      } else {
        $('.select-invoice-month').prop('checked', false);
        sessionStorage.removeItem('adminInvoiceMonths');
      }
    });
  
    $('.select-invoice-month').on('change', function() {
      let retrievedData = sessionStorage.getItem('adminInvoiceMonths');
      let invoiceMonthObject;
    
      if (retrievedData) {
        invoiceMonthObject = JSON.parse(retrievedData);
      } else {
        invoiceMonthObject = {
          invoiceMonthIds: []
        };
      }
    
      let invoiceMonthId = parseInt($(this).val());
      let allInvoiceMonthIds = $('#invoice-month-ids-data').data('invoice-month-ids');

      if ($(this).prop('checked')) {
        invoiceMonthObject.invoiceMonthIds.push(invoiceMonthId);
      } else {
        invoiceMonthObject.invoiceMonthIds = invoiceMonthObject.invoiceMonthIds.filter(id => id != invoiceMonthId);
      }

      if (invoiceMonthObject.invoiceMonthIds.length == allInvoiceMonthIds.length) {
        $('#select-all-invoice-month').prop('checked', true);
      } else {
        $('#select-all-invoice-month').prop('checked', false);
      }
    
      sessionStorage.setItem('adminInvoiceMonths', JSON.stringify(invoiceMonthObject));
    });

    $(".clear-storage-invoice-month").on("click", function() {
      sessionStorage.removeItem('adminInvoiceMonths');
    });

    invoiceMonthLoad();

    function invoiceMonthLoad() {
      let queryString = window.location.search;
      if (!$('#select-all-invoice-month').length || queryString === '') {
        sessionStorage.removeItem('adminInvoiceMonths');
        return;
      }
     
      let retrievedData = sessionStorage.getItem('adminInvoiceMonths');
      if (!retrievedData) {
        return;
      }
      let parsedData = JSON.parse(retrievedData);
    
      let allInvoiceMonthIds = $('#invoice-month-ids-data').data('invoice-month-ids');
      if (parsedData.invoiceMonthIds.length == allInvoiceMonthIds.length) {
        $('#select-all-invoice-month').prop('checked', true);
      }
      $('.select-invoice-month').each(function() {
        let invoiceMonthId = parseInt($(this).val()); 
        if (parsedData.invoiceMonthIds.includes(invoiceMonthId)) {
          $(this).prop('checked', true);
        }
      })
    }
    
    $(".btn-sent-email").on("click", function() {
      $("#form-send-email-invoice-month").submit();
    });
  }

  transport() {
    document.getElementById('conversion_company_id').value = this.idValue
  }
}

$(document).on("change", ".company-check-list", function() {
  $('.btn-register-company').prop('disabled', true);
  if ($('.company-check-list:checked').length === 3) {
    $('.btn-register-company').prop('disabled', false);
  }
});

$(document).on("click", ".search_turbo_frame", function() {
  $(".loading-text").text('検索中...');
  $("#loading-overlay").css('display', 'flex');
});

$(document).on("turbo:submit-end", function() {
  $("#loading-overlay").css('display', 'none');
});

var templates = [];

$(document).on("click", ".get-content-template", function() {
  let cc_id = $(this).data('cc-id');
  let meet_id = $(this).data('meet-id');
  let conversion_id = $(this).data('conversion-id');
  let client_id = $(this).data('client-id');
  $("#message_line").val("");
  $("#select_title").val("");
  $("#img_previews").empty();
  $(".btn-send-message-line").prop("disabled", true);
  
  $.ajax({
    url: '/admins/get-templates',
    type: 'get',
    data: {
      cc_id,
      meet_id,
      conversion_id,
      client_id
    },
    dataType: "json",
    success: function(response) {
      templates = response.templates
      $("#select_title").empty();

      $("#select_title").append($('<option>', {
        value: '',
        text: ''
      }));

      templates.forEach(function(template) {
        $("#select_title").append($('<option>', {
          value: template.id,
          text: template.title
        }));
      });
    },
    error: function(error) {
      console.error("Error ", error);
    }
  });
});

$(document).on("change", "#select_title", function() {
  let templateId = $(this).val();

  let selectedTemplate = templates.find(function(template) {
    return template.id == templateId;
  });

  if (selectedTemplate) {
    $("#img_previews").empty();
    $("#message_line").val(selectedTemplate.content);
    if (selectedTemplate.images_url.length > 0) {
      selectedTemplate.images_url.forEach(url => {
        let img = $('<img/>', {
          src: url,
          class: 'img-preview',
          css: { width: '100px', height: '100px', margin: '5px' }
        });
       let checkbox = $('<input/>', {
          type: 'checkbox',
          class: 'image-checkbox',
          name: 'image_urls[]',
          value: url,
          checked:  true
        });
        let container = $('<div/>', {
          class: 'd-inline-grid'
        });
        container.append(img);
        container.append(checkbox);
        $("#img_previews").append(container);
      })
    }
  }
  toggleDisableBtnMessageLine();
});

$(document).on("input", "#message_line", function() {
  toggleDisableBtnMessageLine();
});

function toggleDisableBtnMessageLine() {
  let messageLineValue = $("#message_line").val().trim();

  if (!messageLineValue) {
    $(".btn-send-message-line").prop("disabled", true);
  } else {
    $(".btn-send-message-line").prop("disabled", false);
  }
}

$(document).on("click", ".open-change-fee", function() {
  let current_fee = $(this).data('current-fee');
  let invoice_id = $(this).data('invoice-id');

  $("#fee_after_discount").text(current_fee.toLocaleString("ja-JP"))
  $("#current_fee").val(current_fee);
  $("#invoice_id").val(invoice_id);
})

$(document).on("click", ".open-change-fee", function() {
  let invoiceId = $(this).data('invoice-id');
  let currentFee = $(this).data('current-fee');
  
  $("#invoice_id").val(invoiceId);
  $("#current_fee").text(currentFee.toLocaleString("ja-JP"));
  $("#new_fee").val("");
  $(".btn-change-fee").prop("disabled", true);
});

$(document).on("click", ".open-client-cv-detail", function() {
  let link = $(this).find('a');
  if (link.length) {
    link[0].click();
  }
});

$.ajaxSetup({
  headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  }
});

$(document).on('click', '.add-favorite path', function(event) {
  event.stopPropagation();
  let element = $(this);
  const conversionId = element.parent().prev().val();

  $.ajax({
    url: `/clients/favorite`,
    type: 'POST',
    dataType: 'json',
    data: { id: conversionId }
  }).done(function(data) {
    if (data.status == "OK") {
      $(`#favorite-by-${conversionId}`).text(data.number_favorites);
      element.parent().replaceWith(`<i class="nav-icon fa-solid fa-star fa-2xl remove-favorite" style="color: #FFD43B;"></i>`);
    }
  });
});

$(document).on('click', '.remove-favorite path', function(event) {
  event.stopPropagation();
  let element = $(this);
  const conversionId = element.parent().prev().val();

  $.ajax({
    url: `/clients/favorite`,
    type: 'DELETE',
    dataType: 'json',
    data: { id: conversionId }
  }).done(function(data) {
    if (data.status == "OK") {
      $(`#favorite-by-${conversionId}`).text(data.number_favorites);
      element.parent().replaceWith(`<i class="nav-icon fa-solid fa-star fa-2xl add-favorite" style="color: #acadaf;"></i>`);
    }
  });
});

$(document).on('click', '.btn-remove-discount-item', function(event) {
  let that = $(this);
  let formId = that.closest('form').attr('id');
  that.parents('tr').remove()
  calculateFeeInvoiceMonth(formId)
})

$(document).on('click', '.btn-add-discount-item', function(event) {
  let that = $(this);
  let classTarget = that.data('class-target');
  let formId = that.closest('form').attr('id');
  let item = $('#' + formId).find('tr.' + classTarget)[0].outerHTML

  item = item.replaceAll('d-none', '')
  that.closest('tr').before(item);
})

function calculateFeeInvoiceMonth(formId) {
  let total_fee_before_tax = 0;
  let total_fee_after_tax = 0;
  let total_tax = 0;
  let vat_tax = $('#' + formId).find(".vat_tax").val();
  let total_invoice_fee = $('#' + formId).find(".total-invoice-fee").text();
  total_invoice_fee = total_invoice_fee.replace(/,/g, '');
  total_invoice_fee = isNaN(total_invoice_fee) ? '' : parseInt(total_invoice_fee)
  let total_discount = 0;
  
  $('#' + formId + ' .discount-total-price').each(function() {
    let that = $(this);
    let price = that.text().trim().replace(/,/g, '');
    if (price != '') {
      total_discount += parseInt(price)
    }
  });
  
  if (isNaN(total_invoice_fee)) {
    total_fee_before_tax = total_discount;
  } else {
    total_fee_before_tax = total_invoice_fee + total_discount;
  }
  total_tax = (total_fee_before_tax * vat_tax) / 100;
  total_tax = parseInt(total_tax)
  total_fee_after_tax = total_fee_before_tax + total_tax
  $('#' + formId).find(".input_total_invoice_fee").val(total_invoice_fee);
  $('#' + formId).find(".input_total_fee_before_tax").val(total_fee_before_tax);
  $('#' + formId).find(".total_fee_before_tax").text(total_fee_before_tax.toLocaleString("ja-JP"));
  $('#' + formId).find(".input_total_tax").val(total_tax);
  $('#' + formId).find(".total_tax").text(total_tax.toLocaleString("ja-JP"));
  $('#' + formId).find(".input_total_fee_after_tax").val(total_fee_after_tax);
  $('#' + formId).find(".total_fee_after_tax").text(total_fee_after_tax.toLocaleString("ja-JP"));
  $('#' + formId).find(".message_total_fee_before_tax_negative").removeClass('text-danger').text("")
  if (total_fee_before_tax <= 0) {
    $('#' + formId).find(".message_total_fee_before_tax_negative").addClass('text-danger').text("⼩計は０以上で入力してください。")
  }
}

$(document).on('keyup', "input[data-type='currency']", function() {
  formatCurrency($(this));
});

$(document).on('blur', "input[data-type='currency']", function() {
  formatCurrency($(this), "blur");
});

function formatCurrencyNumber(number) {
  // format number 1000000 to 1,234,567
  return number.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

function formatCurrency(input, blur) {
  // get input value
  var input_val = input.val();

  // don't validate empty input
  if (input_val === "") { return; }

  // format currency
  input_val = formatCurrencyNumber(input_val);
  input_val = input_val;

  // send updated string to input
  input.val(input_val);
}

$(document).on('input', '.discount-item-name', function(event) {
  let that = $(this);
  let str = that.val().trim();
  if (str != '') {
    that.closest('td').find(".text-danger").remove();
  }
})

$(document).on('input', '.discount-unit-price', function(event) {
  let that = $(this);
  let formId = that.closest('form').attr('id');
  let unit = that.val().trim().replace(/,/g, '');
  let quantity = that.closest('tr').find(".discount-quantity").val().trim().replace(/,/g, '');
  that.closest('tr').find(".discount-total-price").text("");
  let isDiscount = that.closest('tr').find(".is_discount").val();
  let sign = "-";
  if (isDiscount == 'false') {
    sign = "+";
  }

  if (unit != '' && !isNaN(unit) && parseInt(unit) >= 0) {
    if (quantity != '' && !isNaN(quantity)) {
      let total_price = unit * quantity
      that.closest('tr').find(".discount-total-price").text(`${sign}${total_price.toLocaleString("ja-JP")}`)
    }
    if (parseInt(unit) > 0) {
      that.closest('td').find(".text-danger").remove();
    }
  }

  if (unit == '' && that.closest('td').find(".text-danger").length != 0) {
    that.closest('td').find(".text-danger").text("このフィールドは必須です。")
  }

  if (unit != '' && parseInt(unit) == 0 && that.closest('td').find(".text-danger").length != 0) {
    that.closest('td').find(".text-danger").text("1以上で入力してください。")
  }
  calculateFeeInvoiceMonth(formId);
})

$(document).on('input', '.discount-quantity', function(event) {
  let that = $(this);
  let formId = that.closest('form').attr('id');
  let quantity = that.val().trim().replace(/,/g, '');
  let unit = that.closest('tr').find(".discount-unit-price").val().trim().replace(/,/g, '');
  let isDiscount = that.closest('tr').find(".is_discount").val();
  let sign = "-";
  if (isDiscount == 'false') {
    sign = "+";
  }
  that.closest('tr').find(".discount-total-price").text("");
  
  if (quantity != '' && !isNaN(quantity) && parseInt(quantity) >= 0) {
    if (unit != '' && !isNaN(unit)) {
      let total_price = unit * quantity
      that.closest('tr').find(".discount-total-price").text(`${sign}${total_price.toLocaleString("ja-JP")}`)
    }
    if (parseInt(quantity) > 0) {
      that.closest('td').find(".text-danger").remove();
    }
  }

  if (quantity == '' && that.closest('td').find(".text-danger").length != 0) {
    that.closest('td').find(".text-danger").text("このフィールドは必須です。")
  }

  if (quantity != '' && parseInt(quantity) == 0 && that.closest('td').find(".text-danger").length != 0) {
    that.closest('td').find(".text-danger").text("1以上で入力してください。")
  }
  calculateFeeInvoiceMonth(formId);
})

$(document).on('click', '.btn-update-invoice-month', function(event) {
  let that = $(this);
  let formId = that.closest('form').attr('id');
  validateFormInvoiceMonth(formId)

  let formError = $('#' + formId + ' .text-danger').length

  if (formError == 0) {
    that.prop('disabled', true);
    $('#' + formId).submit();
  }
})

function validateFormInvoiceMonth(formId) {
  $('#' + formId + ' .discount-item-name').each(function() {
    let input = $(this);
    let str = input.val().trim();
    input.closest('td').find('.text-danger').remove();

    if (input.closest('tr').hasClass('d-none')) {
      return;
    }
    if (str === '') {
      let divError = "<div class='text-danger'>このフィールドは必須です。</div>";
      input.closest('td').append(divError);
    }
  });

  $('#' + formId + ' .discount-unit-price').each(function() {
    let input = $(this);
    let val = input.val().trim().replace(/,/g, '');
    input.closest('td').find('.text-danger').remove();
    if (input.closest('tr').hasClass('d-none')) {
      return;
    }
    if (val === '') {
      let divError = "<div class='text-danger'>このフィールドは必須です。</div>";
      input.closest('td').append(divError);
    }

    if (val != '' && parseInt(val) == 0) {
      let divError = "<div class='text-danger'>1以上で入力してください。</div>";
      input.closest('td').append(divError);
    }
  });

  $('#' + formId + ' .discount-quantity').each(function() {
    let input = $(this);
    let val = input.val().trim().replace(/,/g, '');
    input.closest('td').find('.text-danger').remove();
    if (input.closest('tr').hasClass('d-none')) {
      return;
    }
    if (val === '') {
      let divError = "<div class='text-danger'>このフィールドは必須です。</div>";
      input.closest('td').append(divError);
    }

    if (val != '' && parseInt(val) == 0) {
      let divError = "<div class='text-danger'>1以上で入力してください。</div>";
      input.closest('td').append(divError);
    }
  });

  let invoice_item = $('#' + formId + ' .invoice_item');
  let invoice_item_val = invoice_item.val().trim();
  invoice_item.closest('div').find('.text-danger').remove();
  
  if (invoice_item_val === '') {
    invoice_item.closest('div').append("<div class='text-danger'>このフィールドは必須です。</div>");
  }
  
  let invoice_count = $('#' + formId + ' .invoice_count');
  let invoice_count_val = invoice_count.val().trim().replace(/,/g, '');
  invoice_count.closest('.div_invoice_count').find('.text-danger').remove();

  if (invoice_count_val === '') {
    invoice_count.closest('.div_invoice_count').append("<div class='text-danger'>このフィールドは必須です。</div>");
  }

  if (invoice_count_val != '' && parseInt(invoice_count_val) == 0) {
    invoice_count.closest('.div_invoice_count').append("<div class='text-danger'>1以上で入力してください。</div>");
  }

  let invoice_fee = $('#' + formId + ' .invoice_fee');
  let invoice_fee_val = invoice_fee.val().trim().replace(/,/g, '');
  invoice_fee.closest('td').find('.text-danger').remove();
  
  if (invoice_fee_val === '') {
    invoice_fee.closest('td').append("<div class='text-danger'>このフィールドは必須です。</div>");
  }

  if (invoice_fee_val != '' && parseInt(invoice_fee_val) == 0) {
    invoice_fee.closest('td').append("<div class='text-danger'>1以上で入力してください。</div>");
  }

  let invoice_quantity = $('#' + formId + ' .invoice_quantity');
  let invoice_quantity_val = invoice_quantity.val().trim().replace(/,/g, '');
  invoice_quantity.closest('td').find('.text-danger').remove();
  
  if (invoice_quantity_val === '') {
    invoice_quantity.closest('td').append("<div class='text-danger'>このフィールドは必須です。</div>");
  }

  if (invoice_quantity_val != '' && parseInt(invoice_quantity_val) == 0) {
    invoice_quantity.closest('td').append("<div class='text-danger'>1以上で入力してください。</div>");
  }
}

$(document).on('input', '.invoice_item', function(event) {
  let that = $(this);
  let str = that.val().trim();
  if (str != '') {
    that.closest('div').find(".text-danger").remove();
  }
})

$(document).on('input', '.invoice_count', function(event) {
  let that = $(this);
  let invoice_count = that.val().trim().replace(/,/g, '');
  
  if (invoice_count != '' && !isNaN(invoice_count) && parseInt(invoice_count) > 0) {
    that.closest('.div_invoice_count').find(".text-danger").remove();
  }

  if (invoice_count == '' && that.closest('.div_invoice_count').find(".text-danger").length != 0) {
    that.closest('.div_invoice_count').find(".text-danger").text("このフィールドは必須です。")
  }

  if (invoice_count != '' && parseInt(invoice_count) == 0 && that.closest('.div_invoice_count').find(".text-danger").length != 0) {
    that.closest('.div_invoice_count').find(".text-danger").text("1以上で入力してください。")
  }
})

$(document).on('input', '.invoice_fee', function(event) {
  let that = $(this);
  let formId = that.closest('form').attr('id');
  let invoice_fee = that.val().trim().replace(/,/g, '');
  let invoice_quantity = that.closest('tr').find(".invoice_quantity").val().trim().replace(/,/g, '');
  that.closest('tr').find(".total-invoice-fee").text("");
  
  if (invoice_fee != '' && !isNaN(invoice_fee) && parseInt(invoice_fee) >= 0) {
    if (invoice_quantity != '' && !isNaN(invoice_quantity)) {
      let total_price = invoice_quantity * invoice_fee
      that.closest('tr').find(".total-invoice-fee").text(total_price.toLocaleString("ja-JP"))
    }
    if (parseInt(invoice_fee) > 0) {
      that.closest('td').find(".text-danger").remove();
    }
  }

  if (invoice_fee == '' && that.closest('td').find(".text-danger").length != 0) {
    that.closest('td').find(".text-danger").text("このフィールドは必須です。")
  }

  if (invoice_fee != '' && parseInt(invoice_fee) == 0 && that.closest('td').find(".text-danger").length != 0) {
    that.closest('td').find(".text-danger").text("1以上で入力してください。")
  }
  calculateFeeInvoiceMonth(formId);
})

$(document).on('input', '.invoice_quantity', function(event) {
  let that = $(this);
  let formId = that.closest('form').attr('id');
  let quantity = that.val().trim().replace(/,/g, '');
  let invoice_fee = that.closest('tr').find(".invoice_fee").val().trim().replace(/,/g, '');
  that.closest('tr').find(".total-invoice-fee").text("");
  
  if (quantity != '' && !isNaN(quantity) && parseInt(quantity) >= 0) {
    if (invoice_fee != '' && !isNaN(invoice_fee)) {
      let total_price = invoice_fee * quantity
      that.closest('tr').find(".total-invoice-fee").text(total_price.toLocaleString("ja-JP"))
    }
    if (parseInt(quantity) > 0) {
      that.closest('td').find(".text-danger").remove();
    }
  }

  if (quantity == '' && that.closest('td').find(".text-danger").length != 0) {
    that.closest('td').find(".text-danger").text("このフィールドは必須です。")
  }

  if (quantity != '' && parseInt(quantity) == 0 && that.closest('td').find(".text-danger").length != 0) {
    that.closest('td').find(".text-danger").text("1以上で入力してください。")
  }
  calculateFeeInvoiceMonth(formId);
})

$(document).on('change', '.invoice_month_note', function() {
  var note = $(this).val();
  var id = $(this).data('invoice-month-id');

  $.ajax({
    url: `/admins/invoice-months/${id}/update-note`,
    type: 'PATCH',
    data: {
      note: note
    },
    success: function(response) {
      $(".alert-success").text("メモを更新しました。")
      $(".alert-success").removeClass("d-none")

      setTimeout(() => {
        $(".alert-success").addClass("d-none")
      }, 3000);
    },
    error: function(xhr, status, error) {
      $(".alert-danger").text("エラーが発生しました。")
      $(".alert-danger").removeClass("d-none")

      setTimeout(() => {
        $(".alert-danger").addClass("d-none")
      }, 3000);
    }
  });
});

$(document).on('click', '#invoice-month-export-csv', function(event) {
  event.preventDefault();

  $('#invoice-month-export-csv-form').submit();
});